import React, { Fragment, useEffect, useState, useRef } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "universal-cookie";
import axios from "axios";
// import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  FaUserCircle,
  FaGoogle,
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaGithub,
  FaBars,
} from "react-icons/fa";
// import "../../../css/Styles.css";
import Images from "../../../img/index.js";
import Cursos from "./Menus/Cursos";
import Administracion from "./Menus/Administracion";
import jwt_decode from "jwt-decode";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import SubCurso from "./Menus/SubCursos/SubCurso";
import tableroImage from "../../../img/hoja.png";
import cursosImage from "../../../img/maestro.png";
import administracionImage from "../../../img/admin.png";
import expandedImage from "../../../img/izq.png";
import collapsedImage from "../../../img/der.png";
import { url } from "../../../config";
import "./Menu.css";
import SessionManager from "../../SessionManagement.js";
import CerrarSesion from "../../CerrarSesion.js";
import mobileLogo from "../../../img/mobile_white.png";  // Ruta de la imagen para móviles
import desktopLogo from "../../../img/academy_white.png";        // Ruta de la imagen para escritorio
import { slide as BurgerMenu } from 'react-burger-menu';

function Menu(props) {
  SessionManager(props);

  const cerrarSesion = () => {
    CerrarSesion(props);
  }
  const [linkClickeado, setLinkClickeado] = useState(false);

  const baseUrl = url;
  const [mostrarContenido, setMostrarContenido] = useState(true);
  // Se define la variable cursos
  const [cursos, setCursos] = useState([]);
  const [cursosdocentes, setCursosdocentes] = useState([]);
  const [selectedCurso, setSelectedCurso] = useState(null);

  const cookies = new Cookies();

  const [userName, setUserName] = useState("");
  const [userRol, setUserRol] = useState("");
  const [userRoles, setUserRoles] = useState([]);
  const [userIdRoles, setUserIdRoles] = useState([]);
  const [userMenus, setUserMenus] = useState([]);
  const [rutas, setRutas] = useState({});

  const [activeOption, setActiveOption] = useState("TABLERO");
  const [contenido, setcontenido] = useState(null);

  const [showParentDropdown, setShowParentDropdown] = useState(false);
  const [showChildDropdown, setShowChildDropdown] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const parentDropdownRef = useRef();

  const closeParentDropdown = () => {
    // Close the parent dropdown after a certain time
    const timeout = setTimeout(() => {
      setShowParentDropdown(false);
    }, 15000); // Automatically close after 15 seconds

    // Clear the timeout when the parent dropdown is opened
    return () => clearTimeout(timeout);
  };

  const closeDropdownOnOutsideClick = () => {
    // Close the dropdown if the user clicks outside
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  };

  const handleClickOutside = (event) => {
    if (
      parentDropdownRef.current &&
      !parentDropdownRef.current.contains(event.target)
    ) {
      setShowParentDropdown(false);
    }
  };

  const toggleSidebarWidth = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const toggleParentDropdown = () => {
    setShowParentDropdown(!showParentDropdown);
    setShowChildDropdown(false); // Ocultar el menú Child
  };

  const toggleChildDropdown = () => {
    setShowChildDropdown(!showChildDropdown);
  };

  const handleMenuClick = (opcion, vista) => {
    // Remover la propiedad "selected" del enlace "TABLERO" si se selecciona otra opción
    if (opcion !== "TABLERO") {
      setActiveOption(opcion);
      setcontenido(vista);
      const tableroLink = document.querySelector("li.selectedes a");
      if (tableroLink) {
        tableroLink.classList.remove("selected");
      }
    }
  };

  const cambiarRol = async (id) => {
    try {
      const request = {
        idRol: parseInt(id),
      };
      const token = cookies.get("token");
      const resultado = await axios.post(
        baseUrl + "usuario_rol/cambiarRol",
        request,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (resultado.data) {
        const rolActivo = resultado.data.idRolActivo;
        cookies.remove("rol_activo", { path: "/" });
        cookies.set("rol_activo", rolActivo, { path: "/" });
      }
      props.history.push("./");
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    let contenido;

    switch (activeOption) {
      case "TABLERO":
        contenido = null;
        break;
      case "CURSOS":
        contenido = <Cursos />;
        break;
      case "Administración":
        contenido = <Administracion />;
        break;
      default:
        contenido = <Administracion />;
    }

    setcontenido(contenido);
  }, [activeOption]);
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    if (!cookies.get("token")) {
      props.history.push("./");
    }

    // Recorrer la tabla cursos para mostrar en el menú

    const fetchCursos = async () => {
      try {
        const token = cookies.get("token");
        const result = await axios.get(baseUrl + "cursos/panel", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (result.data.length > 0) {
          // Realizar los procesos de manera normal
          setCursos(result.data);
        } else {
          // Realizar alguna acción en caso de que la respuesta esté vacía
        }
      } catch (error) {
        console.error(error);
        // Manejar el error de la solicitud al backend
      }
    };

    // Obtener datos del usuario e información pertinente
    const fetchUserInfo = async () => {
      try {
        const token = cookies.get("token");
        const response = await axios.get(baseUrl + "usuarios/userdata", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = response.data;
        setUserName(userData.nombres);
        setUserRol(userData.rol);
        setUserRoles(userData.roles);
        setUserIdRoles(userData.rolesId);
        setUserMenus(userData.menus);
        setRutas(userData.rutas);
      } catch (error) {
        console.error(error);
        // Maneja el error de la solicitud al backend
      }
    };
    fetchUserInfo();
    fetchCursos();
  }, []);
  //}, [cursos]);   //<------------------------------------------------------------DEBE ESTAR----------------------------------------------------

  const [value, onChange] = useState(new Date());

  const redirectToSubCurso = async (idCurso) => {
    try {
      setSelectedCurso(idCurso);
    } catch (error) {
      console.error(error);
      // Manejar el error de la redirección
    }
  };

  useEffect(() => {
    if (selectedCurso === null) {
      setMostrarContenido(true);
    }
  }, [selectedCurso]);

  const renderSubCurso = () => {
    return selectedCurso ? <SubCurso idCurso={selectedCurso} /> : null;
  };

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="bodyy2">
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <a className="navbar-brand" href="/menu" style={{ marginTop: '11px', marginBottom: '12px', marginLeft: '35px' }}>
          <img
            className="lblank"
            src={window.innerWidth <= 768 ? mobileLogo : desktopLogo}
            width={window.innerWidth <= 768 ? "55" : "172"}  // Establecer el ancho según el tipo de dispositivo
            height="55"
            alt="Logo"
          />
        </a>
        {/*navbar moviles*/}
        <div className={`d-lg-none ml-auto ${scrolling ? 'scrolling' : ''}`}>
          <BurgerMenu right isOpen={showNav} onStateChange={(state) => setShowNav(state.isOpen)}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaUserCircle style={{ marginRight: "15px" }} />
              <div style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>{userName}</span>
                <span style={{ fontSize: "14px", color: "gray", display: "block" }}>
                  Rol:
                  <button
                    className="btn btn-gris dropdown-toggle"
                    type="button"
                    id="userDropdown"
                    onClick={toggleChildDropdown}
                    style={{
                      fontSize: "14px",
                      color: "inherit",
                      marginLeft: "5px",
                      padding: "0",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {userRol}
                  </button>
                  <ul
                    className={`perfiles-dropdown-menu dropdown-menu${showChildDropdown ? " show" : ""}`}
                    aria-labelledby="userDropdown"
                    style={{ marginTop: "10px", marginLeft: "-5px" }}
                    onMouseEnter={() => setShowChildDropdown(true)}
                    onMouseLeave={() => setShowChildDropdown(false)}
                  >
                    <li className="perfiles-dropdown-header">Perfiles</li>
                    {userRoles.map((role, index) => (
                      <li key={role.idRol}>
                        <Link
                          className={`perfiles-dropdown-item ${role === userRol ? "disabled" : ""}`}
                          onClick={() => cambiarRol(userIdRoles[index])}
                          style={{
                            pointerEvents: role === userRol ? "none" : "auto",
                            textDecoration: "underline",
                            fontSize: "14px",
                            marginTop: "5px",
                          }}
                        >
                          {role}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </span>


                <Link to="/cambiar_clave" style={{ fontSize: "14px", color: "inherit", textDecoration: "underline" }}>
                  Cambiar contraseña
                </Link>
                <span
                  style={{ fontSize: "14px", cursor: "pointer", color: "inherit", textDecoration: "underline" }}
                  onClick={() => cerrarSesion()}
                >
                  Cerrar sesión
                </span>
              </div>
            </div>
            <hr />
            <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column", justifyContent: "flex-start", margin: "0 0", textAlign: "left" }}>
              <a id="inicio" className="color-night menu-item nav-link" href="/menu" style={{ display: "block", width: "100%" }}>
                INICIO
              </a>
            </div>
            {/* <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column", justifyContent: "flex-start", margin: 0, textAlign: "left" }}>
              <a className="color-night nav-link " href="/Nosotros" style={{ display: "block", width: "100%" }}>
                NOSOTROS
              </a>
            </div>
            <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column", justifyContent: "flex-start", margin: 0, textAlign: "left" }}>
              <a className="color-night nav-link " href="#" style={{ display: "block", width: "100%" }}>
                NOTIFICACIONES
              </a>
            </div> */}

            <hr />
            {userMenus.map((menu, index) => (
              <div key={index} className="menu-item-container">
                {menu === 'TABLERO' ? (
                  <Link
                    to="/menu"
                    onClick={() => {
                      // Manejar clic específico para "Tablero"
                      setActiveOption('TABLERO');
                      setcontenido(null);
                      setSelectedCurso(null);
                    }}
                    className={`menu-item ${activeOption === menu ? "selecteds" : ""}${sidebarCollapsed ? " collapsed" : ""}`}
                    style={{ display: "flex", color: "#606265", alignItems: "flex-start", margin: "0 0 15px", flexDirection: "column", justifyContent: "flex-start", textAlign: "left" }}
                  >
                    {sidebarCollapsed ? (
                      <img src={tableroImage} alt="TABLERO" />
                    ) : (
                      menu
                    )}
                  </Link>
                ) : (
                  <span
                    onClick={() => {
                      if (activeOption !== menu) {
                        handleMenuClick(menu, rutas[index]);
                      }
                    }}
                    className={`menu-item ${activeOption === menu ? "selecteds" : ""}${sidebarCollapsed ? " collapsed" : ""}`}
                    style={{ display: "flex", color: "#606265", alignItems: "flex-start", flexDirection: "column", margin: "0 0 15px", justifyContent: "flex-start", textAlign: "left" }}
                  >
                    {sidebarCollapsed ? (
                      <img src={menu === "CURSOS" ? cursosImage : administracionImage} alt={menu} />
                    ) : (
                      menu
                    )}
                  </span>
                )}
              </div>
            ))}
          </BurgerMenu>
        </div>
        {/*
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setShowNav(!showNav)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        */}

        <div className={`navbar-collapse d-none d-lg-flex ${showNav ? " show" : ""}`}>
          <ul className="navbar-nav mr-auto" style={{ marginLeft: '-32px' }}>
            <li className="nav-item active moving custom-list-item brillo">
              <a className="nav-link blanco bold" href="/menu">
                INICIO
              </a>
            </li>
            {/* <li className="nav-item active moving custom-list-item brillo">
              <Link className="nav-link blanco bold" to="/Nosotros">
                NOSOTROS
              </Link>
            </li>
            <li className="nav-item active moving custom-list-item brillo">
              <a className="nav-link blanco bold" href="/menu">
                NOTIFICACIONES
              </a>
            </li> */}
          </ul>
          <div className="navbar-nav ml-auto position-relative">
            <img
              className="oblank"
              src={require("../../../img/logo_white.png")}
              width="150"
              height="50"
              style={{ marginRight: '41px' }}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                className="btn btn-gris dropdown-toggle w-100"
                type="button"
                id="userDropdown"
                onClick={toggleParentDropdown}
              >
                {/* Icono de perfil
                <FaUserCircle style={{ marginRight: "5px" }} />
                */}
                {userName}
              </button>
            </div>
            <ul
              ref={parentDropdownRef}
              className={`dropdown-menu dropdown-menu-right userName-dropdown${showParentDropdown ? " show" : ""
                }`}
              aria-labelledby="userDropdown"
              style={{ marginRight: "20px" }}
              onMouseEnter={() => {
                setShowParentDropdown(true);
                closeParentDropdown(); // Close after 15 seconds
              }}
              onMouseLeave={() => setShowParentDropdown(false)}
            >
              <li>
                <button
                  className="btn btn-gris dropdown-toggle w-100"
                  type="button"
                  id="userDropdown"
                  onClick={toggleChildDropdown}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "22px !important",
                    marginRight: "20px !important",
                  }}
                >
                  {userRol}
                </button>
                <ul
                  className={`perfiles-dropdown-menu dropdown-menu${showChildDropdown ? " show" : ""
                    }`}
                  aria-labelledby="userDropdown"
                  onMouseEnter={() => setShowChildDropdown(true)}
                  onMouseLeave={() => setShowChildDropdown(false)}
                >
                  <li className="perfiles-dropdown-header">Perfiles</li>
                  {userRoles.map((role, index) => (
                    <li key={role.idRol}>
                      <Link
                        className={`perfiles-dropdown-item ${role === userRol ? "disabled" : ""
                          }`}
                        onClick={() => cambiarRol(userIdRoles[index])}
                        style={{
                          pointerEvents: role === userRol ? "none" : "auto",
                        }}
                      >
                        {role}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <Link className="dropdown-item" to="/cambiar_clave">
                  Cambiar contraseña
                </Link>
              </li>
              <li>
                <a className="dropdown-item" onClick={() => cerrarSesion()}>
                  Cerrar sesión
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* Sidebar */}
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-md-3 col-lg-2 sidebar${sidebarCollapsed ? " collapsed" : ""
              }`}
          >
            <ul className="custom-ul">
              {userMenus.map((menu, index) => (
                <li
                  key={index}
                  className={`custom-li ${activeOption === menu ? "selecteds" : ""
                    }${sidebarCollapsed ? " collapsed" : ""}`}
                >
                  {menu === "TABLERO" ? (
                    <Link
                      to="/menu"
                      onClick={() => {
                        setActiveOption("TABLERO"); // Update activeOption to 'TABLERO'
                        setcontenido(null);
                        setSelectedCurso(null) // Clear the content
                      }}
                      className={`${activeOption === "TABLERO" ? "selecteds" : ""
                        }${sidebarCollapsed ? " collapsed" : ""}`}
                    >
                      {sidebarCollapsed ? (
                        <img src={tableroImage} alt="TABLERO" />
                      ) : (
                        menu
                      )}
                    </Link>
                  ) : (
                    <Link
                      to="#"
                      onClick={() => handleMenuClick(menu, rutas[index])}
                      className={`${activeOption === menu ? "selecteds" : ""}${sidebarCollapsed ? " collapsed" : ""
                        }`}
                      style={{
                        pointerEvents: activeOption === menu ? "none" : "auto",
                      }}
                    >
                      {sidebarCollapsed ? (
                        <img
                          src={
                            menu === "CURSOS"
                              ? cursosImage
                              : administracionImage
                          }
                          alt={menu}
                        />
                      ) : (
                        menu
                      )}
                    </Link>
                  )}
                </li>
              ))}
              <li className="sidebar-toggler">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
                >
                  {sidebarCollapsed ? (
                    <img src={collapsedImage} alt="Collapsed" />
                  ) : (
                    <img src={expandedImage} alt="Expanded" />
                  )}
                </button>
              </li>
            </ul>
          </div>
          {/* Contenido de TABLERO, CURSOS o Administracion */}
          <div
            className={`col-md-9 col-lg-10 main-content${sidebarCollapsed ? " sidebar-collapsed" : ""
              }`}
          >
            {/* Contenido seleccionado del menú vertical*/}
            {contenido === null && mostrarContenido ? (
              <div className="row">
                {/* Mostrar las cartas */}
                <div className="col-md-9 cards-section">
                  <h1 className="titulo-inicio">CURSOS INSCRITOS</h1>
                  {cursos.length === 0 ? ( // Comprueba si no hay cursos
                    <h3 className="nohaycursos">No estás inscrito a ningún curso!</h3>
                  ) : (
                    <div className="rowcard" style={{ paddingRight: '-18.5px', paddingLeft: '-18.5px' }}>
                      {cursos.map((curso) => (

                        <div className="col-md-4 mb-3" style={{ padding: '0px', maxWidth: '376px' }} key={curso.idCurso}>
                          <div
                            className="card cursor-pointer mx-auto"
                            onClick={() => {
                              redirectToSubCurso(curso.idCurso);
                              setMostrarContenido(false);
                            }}
                          >
                            <img
                              className="card-img"
                              src={require("../../../img/oben_2.jpg")}
                              alt="Class 1"
                            />
                            <div className="card-body">
                              <h5 className="card-title">{curso.nom_curso}</h5>
                              <p className="card-text">{curso.descripcion}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {/* Mostrar datos de texto en la columna adicional solo en pantallas grandes */}
                <div
                  className="additional-info-section col-md-3 d-none d-md-block"
                  style={{
                    background: "#FFF",
                    borderRadius: "20px",
                    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
                    height: "700px", // Adjust the height as needed
                    marginTop: "11px", // Adjust the negative margin to move it up
                  }}
                >
                  <div>
                    <br />
                    <h2 className="titulo-calendario">PANEL DE TAREAS</h2>
                    <Calendar onChange={onChange} value={value} />
                    <br />
                    <p className="nohaytareas">No hay tareas pendientes</p>
                  </div>
                </div>
              </div>
            ) : (
              contenido
            )}
            {/* Renderizar SubCurso */}
            {contenido === null && renderSubCurso()}
          </div>
        </div>
      </div>
      {/* Footer en caso de ser necesario
      <footer className="bg-dark text-center text-white">
        <div className="container p-4 pb-0">
          <section className="mb-4">
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="#!"
              role="button"
            >
              <FaFacebook />
            </a>
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="#!"
              role="button"
            >
              <FaTwitter />
            </a>
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="#!"
              role="button"
            >
              <FaGoogle />
            </a>
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="#!"
              role="button"
            >
              <FaInstagram />
            </a>
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="#!"
              role="button"
            >
              <FaGithub />
            </a>
          </section>
        </div>
        <div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          © {new Date().getFullYear()} Oben Academy
        </div>
      </footer>
      */}
    </div>
  );
}

export default Menu;