import React, { createContext, Fragment, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import jwt_decode from 'jwt-decode';
import 'react-calendar/dist/Calendar.css';
import CursoModulo from './CursoModulo';
import CursoInicio from './CursoInicio';
import CursoContenido from './CursoContenido';
import CursoTarea from './CursoTarea';
import CursoForo from './CursoForo';
import CursoExamen from './CursoExamen';
import CursoTareaEnvio from './CursoTareaEnvio';
import CursoExamenCrud from './CursoExamenCrud';
import { Helmet } from 'react-helmet';
import { url } from "../../../../../config";
import MobileAccordionMenu from './MobileAccordionMenu.js';
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"
// Crear el contexto para idCurso
export const IdCursoContext = createContext();

function SubCurso(props) {

    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const { idCurso } = props;
    const [userSubMenus, setUserSubMenus] = useState([]);
    const [rutassub, setRutassub] = useState([]);
    const [idUser, setIdUser] = useState([]);
    const [activeOpcion, setActiveOpcion] = useState('Inicio');
    const [contenidoc, setContenido] = useState(<CursoInicio />);


    // Cerrar sesión


    const cookies = new Cookies();

    const fetchUserId = async () => {
        try {
            const token = cookies.get('token');
            const response = await axios.get(baseUrl + "usuarios/userdata", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const userData = response.data;
            const datos = userData.rolesId;
            setIdUser(datos);
        } catch (error) {
            console.error(error);
            // Maneja el error de la solicitud al backend
        }
    };

    useEffect(() => {
        // Obtener datos del usuario e información pertinente
        const fetchUserInfo = async () => {
            try {
                const token = cookies.get('token');
                const response = await axios.get(baseUrl + "usuarios/userdata", {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const userData = response.data;
                setUserSubMenus(userData.submenus);
                setRutassub(userData.rutassub);
            } catch (error) {
                console.error(error);
                // Maneja el error de la solicitud al backend
            }
        };
        fetchUserInfo();
        fetchUserId();



        const updateContenido = () => {
            switch (activeOpcion) {
                case 'Inicio':
                    setContenido(<CursoInicio idCurso={idCurso} idUser={idUser} />);
                    break;
                case 'Modulo':
                    setContenido(<CursoModulo idCurso={idCurso} idUser={idUser} idPagina={2} />);
                    break;
                case 'Contenido':
                    setContenido(<CursoContenido idCurso={idCurso} idUser={idUser} idPagina={3} />);// PENDIENTE LO DE idPagina = {3}, 
                    //PODEMOS DEFINIRLO EN LA LLAMADA DE LA PAGINA PROPIA
                    break;
                case 'Tarea':
                    setContenido(<CursoTarea idCurso={idCurso} idUser={idUser} idPagina={4} />);
                    break;
                case 'Examenes':
                    setContenido(<CursoExamen idCurso={idCurso} idUser={idUser} idPagina={5} />);
                    break;
                case 'Foro':
                    setContenido(<CursoForo idCurso={idCurso} idUser={idUser} idPagina={6} />);
                    break;
                case 'Envio':
                    setContenido(<CursoTareaEnvio idCurso={idCurso} idUser={idUser} />);
                    break;
                default:
                    setContenido(<CursoInicio idCurso={idCurso} idUser={idUser} />);
                    break;
            }
        };

        updateContenido();


    }, [idCurso, activeOpcion]);

    const handleClick = (opcion) => {
        setActiveOpcion(opcion);

        // Realizar el cambio de contenido en función de la opción seleccionada
        switch (opcion) {
            case 'Inicio':
                setContenido(<CursoInicio idCurso={idCurso} idUser={idUser} />);
                break;
            case 'Modulo':
                setContenido(<CursoModulo idCurso={idCurso} idUser={idUser} />);
                break;
            case 'Contenido':
                setContenido(<CursoContenido idCurso={idCurso} idUser={idUser} />);
                break;
            case 'Tarea':
                setContenido(<CursoTarea idCurso={idCurso} idUser={idUser} />);
                break;
            case 'Examenes':
                setContenido(<CursoExamen idCurso={idCurso} idUser={idUser} />);
                break;
            case 'Foro':
                setContenido(<CursoForo idCurso={idCurso} idUser={idUser} />);
                break;
            default:
                setContenido(<CursoInicio idCurso={idCurso} idUser={idUser} />);
                break;
        }
    };

    return (
        <Fragment><Helmet>
            <style>{`
            footer {
            display: none;
            }
            `}
            </style>
        </Helmet>
            <div className="row">
                <IdCursoContext.Provider value={idCurso}>
                    <div className="col-2 bajando  d-none d-md-block">
                        <div
                            style={{ background: "#FFA778", borderRadius: "20px", width: "300px", height: "438px" }}>
                            <ul style={{ listStyleType: 'none', padding: '0' }}>
                                <li className={activeOpcion === 'Inicio' ? 'selected custom-uppercase' : 'custom-uppercase'} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Link
                                        to="#"
                                        onClick={() => handleClick('Inicio')}
                                        className={activeOpcion === 'Inicio' ? 'selected' : ''}
                                    >
                                        INICIO
                                    </Link>
                                </li>
                                <li className={activeOpcion === 'Modulo' ? 'selected custom-uppercase' : 'custom-uppercase'} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Link
                                        to="#"
                                        onClick={() => handleClick('Modulo')}
                                        className={activeOpcion === 'Modulo' ? 'selected' : ''}
                                    >
                                        MÓDULO
                                    </Link>
                                </li>
                                <li className={activeOpcion === 'Contenido' ? 'selected custom-uppercase' : 'custom-uppercase'} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Link
                                        to="#"
                                        onClick={() => handleClick('Contenido')}
                                        className={activeOpcion === 'Contenido' ? 'selected' : ''}
                                    >
                                        CONTENIDO
                                    </Link>
                                </li>
                                <li className={activeOpcion === 'Examenes' ? 'selected custom-uppercase' : 'custom-uppercase'} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Link
                                        to="#"
                                        onClick={() => handleClick('Examenes')}
                                        className={activeOpcion === 'Examenes' ? 'selected' : ''}
                                    >
                                        EXAMEN
                                    </Link>
                                </li>
                                <li className={activeOpcion === 'Tarea' ? 'selected custom-uppercase' : 'custom-uppercase'} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Link
                                        to="#"
                                        onClick={() => handleClick('Tarea')}
                                        className={activeOpcion === 'Tarea' ? 'selected' : ''}
                                    >
                                        TAREA
                                    </Link>
                                </li>
                                <li className={activeOpcion === 'Foro' ? 'selected custom-uppercase' : 'custom-uppercase'} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Link
                                        to="#"
                                        onClick={() => handleClick('Foro')}
                                        className={activeOpcion === 'Foro' ? 'selected' : ''}
                                    >
                                        FORO
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </IdCursoContext.Provider>

                <div>
                    <MobileAccordionMenu activeOption={activeOpcion} handleClick={handleClick} />
                <br></br>
                </div>

                <div className="col-12 col-md-10 site-content">

                    <div className="custom-subcurso">
                        {contenidoc}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default withRouter(SubCurso);