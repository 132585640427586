import React, { Fragment, useEffect, useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import {
  FaChevronDown, FaArrowUp, FaTrash, FaChevronRight, FaWindowClose, FaArrowDown,
  FaPencilAlt, FaTable, FaArrowCircleLeft, FaPlusSquare, FaPencilRuler, FaLink, FaComments, FaBookOpen
} from 'react-icons/fa';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Popup from 'reactjs-popup';
import Swal from 'sweetalert2';
import CursoModulosCrud from './CursoModuloCrud';
import CursoTareaEnvio from './CursoTareaEnvio';
import ExamenPrevResolution from './CursoExamenes/ExamenPrevResolution';
import CursoForoDiscu from './CursoForoDiscu';
import { CSSTransition } from 'react-transition-group';
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import CursoRenderContenido from './CursoRenderContenido';

function CursoModulo(props) {

  SessionManager(props);

  const cerrarSesion = () => {
    CerrarSesion(props);
  }
  // const baseUrl = "https://localhost:44322/api/";
  const baseUrl = url;
  const { idCurso } = props;
  const { idUser } = props;
  const { idPagina } = props;
  const [examenes, setCursos] = useState([]);
  const [tareas, setTareas] = useState([]);
  const [nombremodulos, setNombreModulos] = useState([]);
  const [nombreClase, setNombreClase] = useState('');
  const [mostrarCrud, setMostrarCrud] = useState(false);
  const [mostrarTarea, setMostrarTarea] = useState(false);
  const [mostrarForo, setMostrarForo] = useState(false);
  const [mostrarExamen, setMostrarExamen] = useState(false);
  const [iDCurso, setIDCurso] = useState(null);
  const [idExamen, setIdExamen] = useState(null);
  const [idModulo, setIdModulo] = useState(null);
  const [idForo, setIdForo] = useState(null);
  const [showCrud, setShowCrud] = useState(true);
  const [selectedExamen, setSelectedExamen] = useState(null);
  const [selectedTarea, setSelectedTarea] = useState(null);
  const [selectedForum, setSelectedForum] = useState(null);
  const [foros, setForos] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
  const [validacionCompleta, setValidacionCompleta] = useState(false);
  const [idModulitos, setIdModulitos] = useState([]);
  const [contenido, setContenido] = useState([]);
  const [mostrarContenido, setMostrarContenido] = useState(false);
  const [idModuloActual, setIdModuloActual] = useState(null);
  const [idCursoOptions, setIdCursoOptions] = useState([]); // Holds the list of courses
  // State to track the open collapse. This will track the index of the open collapse.
  const [abrirCollapse, setAbrirCollapse] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(
    localStorage.getItem('buttonClicked') === 'true'
  );

  const [idTarea, setIdTarea] = useState(null);

  const cookies = new Cookies();

  const handleLeerContenido = (idModulo) => {
    setIdModuloActual(idModulo);
    setMostrarContenido(true);
  };

  const handleocultarContenido = () => {
    setMostrarContenido(false);
  };


  const handleMostrarCrud = () => {
    setMostrarCrud(true);
  };

  const handleOcultarCrud = () => {
    setMostrarCrud(false);
  };

  const handleMostrarExamen = (idExamen) => {
    setIdExamen(idExamen);
    setMostrarExamen(true);
    setMostrarTarea(false);
    setMostrarForo(false);
    setMostrarCrud(false);
  };

  const handleOcultarExamen = () => {
    setMostrarExamen(false);
  };

  const handleMostrarTarea = (idModulos, idTarea) => {
    setIdModulo(idModulos);
    setIdTarea(idTarea);
    setMostrarTarea(true);
    setMostrarForo(false);
    setMostrarCrud(false);
    setMostrarExamen(false);
  };


  const handleOcultarTarea = () => {
    setMostrarTarea(false);
  };

  const handleMostrarForo = (idModulos, idForo) => {
    setIdModulo(idModulos);
    setIdForo(idForo);
    setMostrarForo(true);
    setMostrarTarea(false);
    setMostrarCrud(false);
    setMostrarExamen(false);
  };

  const handleOcultarForo = () => {
    setMostrarForo(false);
  };

  // Function to toggle collapses
  const DesplegarCollapse = (index) => {
    setAbrirCollapse(abrirCollapse === index ? null : index);
  };

  const fetchForos = async () => {
    try {
      const token = cookies.get('token');
      const requestBody = { idCurso: idCurso };
      const result = await axios.post(baseUrl + "Foros/listarForosMenu", requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        setForos(result.data);
      } else {
        // Handle the response if it's not successful
      }
    } catch (error) {
      console.error(error);
      // Handle the error of the request to the backend
    }
  };


  // Function to register exam response
  const registerExamResponse = async (idExamen) => {
    try {
      const token = cookies.get('token');
      const requestBody = { idExamen: idExamen }; // Use the idExamen passed as a parameter
      const response = await axios.post(
        baseUrl + 'Examen_respuesta/registrarExamen_respuesta',
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        // Exam response registration was successful, handle the response as neededredirectToVPrevia
      } else {
        // Handle errors or show a message to the user
      }
    } catch (error) {
      console.error(error);
      // Handle errors or show a message to the user
    }
  };

  const RolAlmacenado = cookies.get("rol_activo");
  const usuarioId = parseInt(RolAlmacenado, 10);

  const renderExamen = () => {
    if (selectedExamen) {
      return <ExamenPrevResolution idExamen={selectedExamen.idExamen} idCurso={idCurso} />
    }
    return null;
  };

  const renderTarea = () => {
    if (selectedTarea) {
      return <CursoTareaEnvio idCurso={idCurso} idModulos={selectedTarea.idModulos} idTarea={selectedTarea.idTarea} />;
    }
    return null;
  };

  const renderForo = () => {
    if (selectedForum) {
      return <CursoForoDiscu idCurso={idCurso} idModulos={selectedForum.idModulos} idForo={selectedForum.idForo} />;
    }
    return null;
  };

  /*------------------------------COMPONENTES PARA AGREGAR MODULO-------------------------------------------*/

  const [nuevoModulo, setNuevoModulo] = useState({
    nombre_modulo: '',
    enlace_sesion: '',
    enlace_informativo: '',
    estado: false,
    idCurso: props.idCurso,
  });

  // Capturando datos para el nuevoModulo.
  const ModuloNuevo = {
    nombre_modulo: nuevoModulo.nombre_modulo,
    estado: nuevoModulo.estado,
    enlace_informativo: nuevoModulo.enlace_informativo,
    enlace_sesion: nuevoModulo.enlace_sesion,
    idCurso: parseInt(props.idCurso),
  };
  const resetForm = () => {
    setNuevoModulo({
      nombre_modulo: '',
      enlace_sesion: '',
      enlace_informativo: '',
      estado: false,
    });
    // Restablecer otras variables relacionadas si las hay
  };

  const fetchCursos = async () => {
    try {
      const token = cookies.get('token');
      const requestBody = { idCurso: idCurso };
      const result = await axios.post(baseUrl + "modulos/listarModulos", requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        setCursos(result.data);
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };


  // Agregar nuevo Modulo:
  const handleChange = (event) => {
    const { name, value } = event.target;
    setNuevoModulo((prevState) => ({
      ...prevState,
      [name]: name === 'idCurso' ? parseInt(value) : value,

    }));
  };

  const agregarModulo = async (event) => {
    event.preventDefault(); // Cancelar el evento por defecto
    try {
      if (editarModulo) {
        // EDITAR CURSO
        //
        // Establecer los valores del curso seleccionado al formulario popup
        //
        // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
        if (
          !nuevoModulo.nombre_modulo ||
          !nuevoModulo.enlace_informativo ||
          !nuevoModulo.enlace_sesion
        ) {
          throw new Error('Por favor, complete todos los campos.');
        }
        const token = cookies.get('token');
        const response = await axios.post(
          baseUrl + 'modulos/editarModulo',
          nuevoModulo,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Realizar alguna acción después de registrar el curso exitosamente
        // Por ejemplo, cerrar el popup y volver a cargar la lista de cursos
        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: 'Cambio exitoso',
            text: 'Modulo actualizado correctamente!',
            showConfirmButton: true,
            confirmButtonText: 'Ok'
          });
          closePopup();
          fetchCursos();
          resetForm();
        }
      } else {
        // AGREGAR CURSO
        //
        // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
        if (
          !nuevoModulo.nombre_modulo ||
          !nuevoModulo.enlace_sesion ||
          !nuevoModulo.enlace_informativo
        ) {
          throw new Error('Por favor, complete todos los campos.');
        }
        const token = cookies.get('token');

        // Llamar a la API para agregar un curso, docentes y estudiantes
        const response = await axios.post(
          baseUrl + 'modulos/registrarModulo',
          ModuloNuevo,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Realizar alguna acción después de registrar el curso exitosamente
        // cerrar el popup y volver a cargar la lista de cursos
        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: 'Registro exitoso',
            text: 'Curso creado correctamente!',
            showConfirmButton: true,
            confirmButtonText: 'Ok'
          });
          closePopup();
          fetchCursos();
          resetForm();
        }
      }
    } catch (error) {
      // MANEJO DE ERRORES
      if (error.message === 'Por favor, complete todos los campos.') {
        Swal.fire({
          icon: 'error',
          title: 'Campos incompletos!',
          text: error.message
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Lo sentimos!',
          text: error.message,
        });
      }
    }
  };

  const eliminarCurso = async (idModulos) => {
    Swal.fire({
      title: '¿Estás seguro de que deseas ELIMINAR este modulo?',
      text: 'El modulo y su contenido no se podrán recuperar',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const request = {
            idModulos: idModulos
          };
          const token = cookies.get('token');
          const response2 = await axios.post(baseUrl + 'modulos/eliminarModulo', request, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          });

          // Mostrar Sweet Alert
          Swal.fire({
            icon: 'success',
            title: 'Curso eliminado correctamente',
            showConfirmButton: true,
            confirmButtonText: 'Ok'
          });
          fetchCursos();

        } catch (error) {
          console.error(error);
          // Manejar el error de la solicitud al backend
        }
      } else {
        setCursoSeleccionado(null); // Restablecer a null
      }
    });
  };

  // Popup Editar
  const [editarModulo, setEditarModulo] = useState(false);

  const abrirEditarModulo = (evento, modulo) => {
    evento.stopPropagation(); // Esto evitará que el evento de clic se propague a otros elementos.
    setEditarModulo(true);
    setShowPopup(true);


    // Asignar los valores del curso seleccionado al objeto nuevoModulo
    setNuevoModulo({
      idModulos: modulo.idModulos,
      nombre_modulo: modulo.nombre_modulo,
      enlace_informativo: modulo.enlace_informativo,
      enlace_sesion: modulo.enlace_sesion,
      idCurso: modulo.idCurso,
      estado: modulo.estado
    });
  };

  const togglePopup = () => {
    resetForm();
    setEditarModulo(false);
    setShowPopup(true);
    return true;
  };

  const closePopup = () => {
    setEditarModulo(false);
    setShowPopup(false);
    return true;
  };

  const handleHacerTarea = (idTarea) => {
    setIdTarea(idTarea);
    setMostrarTarea(true);
  };

  /*-------------------------------------------------------------------------------------------------------*/
  

  const fetchNombreCurso = async () => {
    try {
      const token = cookies.get('token');
      const requestBody = { idCurso: idCurso };
      const result = await axios.post(baseUrl + "cursos/buscarCurso", requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        setNombreClase(result.data);
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  const fetchExamenesinModulos = async () => {
    try {
      const token = cookies.get('token');
      const requestBody = { idCurso: idCurso };
      const result = await axios.post(baseUrl + "examen/listarExamenesMenu", requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        setCursos(result.data);
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  const fetchTareasinModulos = async () => {
    try {
      const token = cookies.get('token');
      const requestBody = { idCurso: idCurso };
      const result = await axios.post(baseUrl + "tareas/listarTareasMenu", requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        setTareas(result.data);
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };




  const fetchContenido = async (idModulo) => {
    try {
      const token = cookies.get('token');
      const requestBody = { idModulos: idModulo };
      const result = await axios.post(baseUrl + "Paginas_contenido/BuscarPaginas_contenidoModulo", requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        setContenido(prevContenido => ({
          ...prevContenido,
          [idModulo]: result.data.contenido
        }));
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  //<------------------------------------------MANEJANDO LLAMADAS--------------------------------------------------------------
  //El problema de las múltiples solicitudes parece estar relacionado con las dependencias del
  //useEffect. Si nombremodulos, idCurso, o tareas cambian, se volverá a ejecutar todo el 
  //bloque de useEffect, lo que incluye las llamadas a todas las funciones de fetch que has definido.

  // Este useEffect se ejecutará una sola vez al montar el componente ya que no tiene dependencias.
  useEffect(() => {
    fetchNombreCurso();
    // No agregamos dependencias a este useEffect si solo queremos que se ejecute una vez.
  }, [idCurso]);

  // Este useEffect manejará la carga de módulos y solo se ejecutará cuando idCurso cambie.
  useEffect(() => {
    fetchModulos();
    // Agrega cualquier otra función que solo necesite ejecutarse cuando idCurso cambie.
  }, [idCurso]);

  // Este useEffect manejará la carga de exámenes y también solo se ejecutará cuando idCurso cambie.
  useEffect(() => {
    fetchExamenesinModulos();
    // Si necesitas llamar a otras funciones similares, añádelas aquí.
  }, [idCurso]);

  // Este useEffect manejará la carga de tareas y se ejecutará cuando idCurso cambie.
  useEffect(() => {
    fetchTareasinModulos();
    // Agrega otras funciones si necesitan ejecutarse cuando idCurso cambie.
  }, [idCurso]);

  // Este efecto es para el manejo de acciones después de un click de botón, por ejemplo.
  useEffect(() => {
    if (buttonClicked) {
      localStorage.setItem('buttonClicked', 'true');
      // Llama a cualquier función que deba ejecutarse después de un click.
    }
  }, [buttonClicked]);

  //Al separar estas llamadas, previenes que una llamada fetch no necesaria se realice debido a cambios
  //en el estado que no afectan a esa llamada en particular. Además, esto hace que tu componente sea
  //más predecible y más fácil de mantener.

  //<--------------------------------------------------------------------------------------------------------

  //<------------------------------------------MANEJANDO REORGANIZACION------------------------------------------
  // 0
  // Estado para rastrear si se han cargado las tareas
  const [modulosFetched, setModulosFetched] = useState(false);
  const [modulosOrdenados, setModulosOrdenados] = useState([]);
  const [alertaMostrada, setAlertaMostrada] = useState(false);

  //1
  const fetchModulos = async () => {
    try {
      const token = cookies.get('token');
      const requestBody = { idCurso: idCurso };
      const result = await axios.post(baseUrl + "modulos/listarModulos", requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        console.log("Respuesta exitosa en fetchExamenes:", result.data);
        setNombreModulos(result.data);

        const idModulitos = result.data.map(modulo => modulo.idModulos);
        setIdModulitos(idModulitos);

        // Llamar a fetchContenido para cada idModulo
        idModulitos.forEach(idModulo => {
          fetchContenido(idModulo);
        });

        setModulosFetched(true);
        fetchForos(); // Fetch forums here
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  // 2
  // Función para manejar la confirmación de movimiento de tareas
  const confirmMoveForo = (currentIndex, targetIndex) => {
    if (!alertaMostrada) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: '¿Deseas mover este modulo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, moverlo',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          // Marcar la alerta como mostrada
          handleMoveTarea(currentIndex, targetIndex);
        }
      });

      // Marcar la alerta como mostrada
      setAlertaMostrada(true);
    } else {
      // Si la alerta ya se mostró antes, simplemente realizar la acción
      handleMoveTarea(currentIndex, targetIndex);
    }
  };

  // 3
  // Función para manejar el movimiento de tareas
  const handleMoveTarea = (currentIndex, targetIndex) => {
    const updatedmodulos = [...nombremodulos];
    const temp = updatedmodulos[currentIndex];
    updatedmodulos[currentIndex] = updatedmodulos[targetIndex];
    updatedmodulos[targetIndex] = temp;

    // Actualizar el estado con los tareas reordenados
    setNombreModulos(updatedmodulos);

    // Actualizar el estado de los IDs de los tareas reordenados
    setModulosOrdenados(updatedmodulos.map(modulo => modulo.idModulos));

    // Aquí puedes enviar el nuevo orden al backend o realizar cualquier otra operación necesaria
    guardarOrdenModulos(updatedmodulos);
  };

  // 4
  const guardarOrdenModulos = async (updatedmodulos) => {
    try {
      const token = cookies.get('token');
      const requestBody = {
        idCurso: idCurso,
        idPagina: idPagina,
        nuevoOrden: updatedmodulos.map(modulo => modulo.idModulos).join(",")
      };
      const result = await axios.post(baseUrl + "OrdenModulo/editarOrdenModulos", requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        console.log("Orden de módulos guardado exitosamente");
      } else {
        // Manejar errores o respuestas no exitosas
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  //????
  const ordenarTareasSegunId = (modulosOrdenados, modulosOriginales) => {
    return modulosOrdenados.map(idModulos =>
      modulosOriginales.find(modulo => modulo.idModulos === idModulos)
    );
  };

  // 5
  const fetchModulosOrdenados = async () => {
    try {
      console.log("Ejecutando useEffect para fetchModulosOrdenados...");
      const token = cookies.get('token');
      const requestBody = { idCurso: idCurso, idPagina: 2 };
      const result = await axios.post('https://localhost:44322/api/OrdenModulo/listarModulos', requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        console.log("Respuesta exitosa en fetchModulosOrdenados:", result.data);
        setModulosOrdenados(result.data);
      } else {
        console.error("Error al obtener los módulos ordenados");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 6
  // Efecto para cargar los tareas al montar el componente o cuando cambia el estado de buttonClicked
  useEffect(() => {
    fetchModulos();
  }, [idCurso]);

  useEffect(() => {
    if (modulosFetched) { // Solo ejecuta si fetchModulos se ha completado
      console.log("Ejecutando useEffect para fetchModulosOrdenados...");
      fetchModulosOrdenados();
    }
  }, [modulosFetched]);  // Ahora solo depende de modulosFetched
  //<---------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    // Realiza la validación cuando se actualicen los valores relevantes
    if (nuevoModulo.nombre_modulo && nuevoModulo.enlace_sesion && nuevoModulo.enlace_informativo) {
      setValidacionCompleta(true);
    } else {
      setValidacionCompleta(false);
    }
  }, [nuevoModulo.nombre_modulo, nuevoModulo.enlace_sesion, nuevoModulo.enlace_informativo]);

  return (
    <Fragment>
      {renderExamen()}
      {renderTarea()}
      {renderForo()}
      {showCrud && (
        <div className='container'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {nombreClase.nom_curso && <h5 style={{ margin: '0', marginRight: '10px' }}>{nombreClase.nom_curso}</h5>}
            <FaChevronRight color="#F47735" />
            <h5 style={{ margin: '0', marginLeft: '10px' }}>Portafolio de Módulos</h5>
            <FaChevronRight color="#F47735" />
            <h5 style={{ margin: '0', marginLeft: '10px' }}>Módulos</h5>
          </div>
          <hr />

          {mostrarCrud ? (
            <>
              <div className="d-flex justify-content-end mb-3">
                <Button className="tealbu direction-button" onClick={handleOcultarCrud}>
                  Volver lista de Módulos <FaArrowCircleLeft className="fa-icon" />
                </Button>
              </div>
              <CursoModulosCrud idCurso={idCurso} />
            </>
          ) : mostrarExamen ? (
            <>
              <div className="d-flex justify-content-end mb-3">
                <Button className="tealbu direction-button" onClick={handleOcultarExamen}>
                  Volver lista de Módulos <FaArrowCircleLeft className="fa-icon" />
                </Button>
              </div>
              <ExamenPrevResolution idExamen={idExamen} idCurso={idCurso} />
            </>
          ) : mostrarTarea ? (
            <>
              <div className="d-flex justify-content-end mb-3">
                <Button className="tealbu direction-button" onClick={handleOcultarTarea}>
                  Volver lista de Módulos <FaArrowCircleLeft className="fa-icon" />
                </Button>
              </div>
              <CursoTareaEnvio idCurso={idCurso} idModulos={idModulo} idTarea={idTarea} />
            </>
          ) : mostrarContenido ? (
            <>
              <div className="d-flex justify-content-end">
                <Button className="tealbu direction-button" onClick={handleocultarContenido}>
                  Volver a la lista de Módulos <FaArrowCircleLeft className="fa-icon" />
                </Button>
              </div>
              <CursoRenderContenido idCurso={idCurso} idModulo={idModuloActual} />
            </>
          ) : mostrarForo ? (
            <>
              <div className="d-flex justify-content-end mb-3">
                <Button className="tealbu direction-button" onClick={handleOcultarForo}>
                  Volver lista de Módulos <FaArrowCircleLeft className="fa-icon" />
                </Button>
              </div>
              <CursoForoDiscu idCurso={idCurso} idModulos={idModulo} idForo={idForo} />
            </>
          ) : (
            <>
              {usuarioId === 4 || usuarioId === 5 || usuarioId === 7 ? (// Establecer el ancho según el tipo de dispositivo
                <div className="d-flex justify-content-end mb-3">
                  <Button className="verdetabla direction-button" onClick={handleMostrarCrud}>
                    Ver tabla de Módulos <FaTable className="fa-icon" />
                  </Button>&nbsp;&nbsp;
                  <Button className="azulbu direction-button" onClick={togglePopup}>
                    Agregar Módulo  <FaPlusSquare className="fa-icon" />
                  </Button>
                </div>
              ) : null}

              {modulosOrdenados.length > 0 ? (
                <div className='item-group-condensed ig-header' style={{ marginBottom: '10px' }}>
                  {modulosOrdenados.map((moduloId, index) => {
                    const modulos = nombremodulos.find(t => t.idModulos === moduloId); // Buscar el módulo por ID
                    if (!modulos) return null; // Si no se encuentra el módulo, no renderizar

                    return (
                      <Fragment key={index}>
                        <div className="collapse-header" onClick={() => DesplegarCollapse(index)}>
                          {/*BOTONES DE REORDENAMIENTO*/}
                          {/*{usuarioId === 4 || usuarioId === 5 ? (
                            <div className="d-flex align-items-center" style={{ flexDirection: 'column' }}>
                              {index > 0 && (
                                <Button className="boton-arriba blue-outline-button mb-2" onClick={() => confirmMoveForo(index, index - 1)}>
                                  <FaArrowUp />
                                </Button>
                              )}
                              {index < nombremodulos.length - 1 && (
                                <Button className="boton-abajo green-outline-button" onClick={() => confirmMoveForo(index, index + 1)}>
                                  <FaArrowDown />
                                </Button>
                              )}
                            </div>
                          ) : null}&nbsp;&nbsp;*/}
                          {abrirCollapse === index ? <FaChevronDown color="#F47735" /> : <FaChevronRight color="#F47735" />}
                          <h4 className="naranjah4 truncate-text-listar-modulo-modulo">{modulos.nombre_modulo}</h4>
                          {usuarioId === 4 || usuarioId === 5 || usuarioId === 7 ? (
                            <div className="ml-auto">
                              <Button className="yellow-outline-button" onClick={(e) => abrirEditarModulo(e, modulos)}>
                                <FaPencilAlt color="#F47735" />
                              </Button>
                              <Button
                                className="red-outline-button"
                                onClick={() => eliminarCurso(modulos.idModulos)}
                              >
                                <FaTrash color="red" />
                              </Button>
                            </div>
                          ) : null}
                        </div>
                        <Collapse in={abrirCollapse === index}>
                          <div className={`collapse-content text-contenedor ${abrirCollapse === index ? 'expanded' : ''}`}>
                            {/* Verificar si hay contenido para este módulo */}
                            {contenido[modulos.idModulos] ? (
                              <div>
                                <h4 className='lineamodulop'>Contenido</h4>
                                <hr className='lineamodulo' />
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '10px', marginRight: '10px' }}>
                                  <p className="truncate-modulos-main"><strong>Revisa el contenido de la sesión →</strong></p>
                                  <p style={{ margin: 0 }}>
                                    <Button className="marronbu direction-button" onClick={() => handleLeerContenido(modulos.idModulos)}>
                                      Revisar Contenido <FaBookOpen className="fa-icon" />
                                    </Button>
                                  </p>
                                </div>
                                <hr className='lineamodulo' />
                              </div>
                            ) : null}
                            {/* Otros datos relevantes de la tarea */}
                            {examenes
                              .filter((examen) => examen.idModulos === modulos.idModulos).length > 0 ? (
                              <div>
                                {/* Render the exams section here */}
                                <h4 className='lineamodulop'>Examen</h4>
                                <hr className='lineamodulo' />
                                {/* You can map through the exams and display them */}
                                {examenes
                                  .filter((examen) => examen.idModulos === modulos.idModulos)
                                  .map((examen, examIndex) => (
                                    <>
                                      <div key={examIndex} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '10px', marginRight: '10px' }}>
                                        <p className="truncate-modulos-main"><strong>{examen.titulo}</strong></p>
                                        <p style={{ margin: 0 }}>
                                          <Button
                                            className="examenbu direction-button"
                                            onClick={() => handleMostrarExamen(examen.idExamen)}>
                                            Realizar Examen <FaPencilRuler className="fa-icon" />
                                          </Button>
                                        </p>
                                      </div>
                                      <hr className='lineamodulo' />
                                    </>
                                  ))}
                              </div>
                            ) : <></>}
                            {/* Otros datos relevantes de la tarea */}
                            {tareas
                              .filter((tarea) => tarea.idModulos === modulos.idModulos).length > 0 ? (
                              <div>
                                {/* Render the exams section here */}
                                <h4 className='lineamodulop'>Tarea</h4>
                                <hr className='lineamodulo' />
                                {/* You can map through the exams and display them */}
                                {tareas
                                  .filter((tarea) => tarea.idModulos === modulos.idModulos)
                                  .map((tarea, tareaIndex) => (
                                    <>
                                      <div key={tareaIndex} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '10px', marginRight: '10px' }}>
                                        <p className="truncate-modulos-main" style={{ textAlign: 'left', margin: 0 }}><strong>{tarea.titulo}</strong></p>
                                        <p style={{ margin: 0 }}>
                                          <Button
                                            className="tareabu direction-button"
                                            onClick={() => handleMostrarTarea(tarea.idModulos, tarea.idTarea)}
                                          >
                                            Realizar Tarea <FaLink className="fa-icon" />
                                          </Button>
                                        </p>
                                      </div>
                                      <hr className='lineamodulo' />
                                    </>
                                  ))}
                              </div>
                            ) : <></>}
                            {foros
                              .filter((foro) => foro.idModulos === modulos.idModulos).length > 0 ? (
                              <div>
                                <h4 className='lineamodulop'>Foro</h4>
                                <hr className='lineamodulo' />
                                {/* Add a similar section for forums */}
                                {foros
                                  .filter((foro) => foro.idModulos === modulos.idModulos)
                                  .map((foro, foroIndex) => (
                                    <>
                                      <div key={foroIndex} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '10px', marginRight: '10px' }}>
                                        <p className="truncate-modulos-main-foro" tyle={{ textAlign: 'left', margin: 0 }}><strong>{foro.titulo}</strong></p>
                                        <p style={{ margin: 0 }}>
                                          <Button
                                            className="btn-block forobu direction-button"
                                            onClick={() => handleMostrarForo(foro.idModulos, foro.idForo)}
                                          >
                                            Responder Foro <FaComments className="fa-icon" />
                                          </Button>
                                        </p>
                                      </div>
                                      <hr className='lineamodulo' />
                                    </>
                                  ))}
                              </div>
                            ) : <></>}
                          </div>

                        </Collapse>
                      </Fragment>
                    );
                  })}
                </div>
              ) : nombremodulos.length > 0 ? (
                nombremodulos.map((modulos, index) => {
                  return (

                    <Fragment key={index}>
                      <div className="collapse-header" onClick={() => DesplegarCollapse(index)}>
                        {/*BOTONES DE REORDENAMIENTO2*/}
                        {/*{usuarioId === 4 || usuarioId === 5 ? (
                          <div className="d-flex align-items-center" style={{ flexDirection: 'column' }}>
                            {index > 0 && (
                              <Button className="boton-arriba blue-outline-button mb-2" onClick={() => confirmMoveForo(index, index - 1)}>
                                <FaArrowUp />
                              </Button>
                            )}
                            {index < nombremodulos.length - 1 && (
                              <Button className="boton-abajo green-outline-button" onClick={() => confirmMoveForo(index, index + 1)}>
                                <FaArrowDown />
                              </Button>
                            )}
                          </div>
                        ) : null}&nbsp;&nbsp;*/}
                        {abrirCollapse === index ? <FaChevronDown color="#F47735" /> : <FaChevronRight color="#F47735" />}
                        <h4 className="naranjah4 cambio-truncate-moviles me-2">{modulos.nombre_modulo}</h4>
                        {usuarioId === 4 || usuarioId === 5 || usuarioId === 7 ? (
                          <div className="ml-auto">
                            <Button className="yellow-outline-button" onClick={(e) => abrirEditarModulo(e, modulos)}>
                              <FaPencilAlt color="#F47735" />
                            </Button>
                            <Button
                              className="red-outline-button"
                              onClick={() => eliminarCurso(modulos.idModulos)}
                            >
                              <FaTrash color="red" />
                            </Button>
                          </div>
                        ) : null}
                      </div>
                      <Collapse in={abrirCollapse === index}>
                        <div className={`collapse-content text-contenedor ${abrirCollapse === index ? 'expanded' : ''}`}>
                          {/* Verificar si hay contenido para este módulo */}
                          {contenido[modulos.idModulos] ? (
                            <div>
                              <h4 className='lineamodulop'>Contenido</h4>
                              <hr className='lineamodulo' />
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '10px', marginRight: '10px' }}>
                                <div style={{ marginLeft: 'auto' }}> {/* Añade marginLeft: 'auto' aquí */}
                                  <Button className="marronbu direction-button" onClick={() => handleLeerContenido(modulos.idModulos)}>
                                    Revisar Contenido <FaBookOpen className="fa-icon" />
                                  </Button>
                                </div>
                              </div>
                              <hr className='lineamodulo' />
                            </div>
                          ) : null}
                          {/* Otros datos relevantes de la tarea */}
                          {examenes
                            .filter((examen) => examen.idModulos === modulos.idModulos).length > 0 ? (
                            <div>
                              {/* Render the exams section here */}
                              <h4 className='lineamodulop'>Examen</h4>
                              <hr className='lineamodulo' />
                              {/* You can map through the exams and display them */}
                              {examenes
                                .filter((examen) => examen.idModulos === modulos.idModulos)
                                .map((examen, examIndex) => (
                                  <>
                                    <div key={examIndex} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '10px', marginRight: '10px' }}>
                                      <p className="truncate-modulos-main"><strong>{examen.titulo}</strong></p>
                                      <p style={{ margin: 0 }}>
                                        <Button
                                          className="examenbu direction-button"
                                          onClick={() => handleMostrarExamen(examen.idExamen)}>
                                          Realizar Examen <FaPencilRuler className="fa-icon" />
                                        </Button>
                                      </p>
                                    </div>
                                    <hr className='lineamodulo' />
                                  </>
                                ))}
                            </div>
                          ) : <></>}
                          {/* Otros datos relevantes de la tarea */}
                          {tareas
                            .filter((tarea) => tarea.idModulos === modulos.idModulos).length > 0 ? (
                            <div>
                              {/* Render the exams section here */}
                              <h4 className='lineamodulop'>Tarea</h4>
                              <hr className='lineamodulo' />
                              {/* You can map through the exams and display them */}
                              {tareas
                                .filter((tarea) => tarea.idModulos === modulos.idModulos)
                                .map((tarea, tareaIndex) => (
                                  <>
                                    <div key={tareaIndex} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '10px', marginRight: '10px' }}>
                                      <p className="truncate-modulos-main" style={{ textAlign: 'left', margin: 0 }}><strong>{tarea.titulo}</strong></p>
                                      <p style={{ margin: 0 }}>
                                        <Button
                                          className="tareabu direction-button"
                                          onClick={() => handleMostrarTarea(tarea.idModulos, tarea.idTarea)}
                                        >
                                          Realizar Tarea <FaLink className="fa-icon" />
                                        </Button>
                                      </p>
                                    </div>
                                    <hr className='lineamodulo' />
                                  </>
                                ))}
                            </div>
                          ) : <></>}
                          {foros
                            .filter((foro) => foro.idModulos === modulos.idModulos).length > 0 ? (
                            <div>
                              <h4 className='lineamodulop'>Foro</h4>
                              <hr className='lineamodulo' />
                              {/* Add a similar section for forums */}
                              {foros
                                .filter((foro) => foro.idModulos === modulos.idModulos)
                                .map((foro, foroIndex) => (
                                  <>
                                    <div key={foroIndex} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '10px', marginRight: '10px' }}>
                                      <p className="truncate-modulos-main-foro" tyle={{ textAlign: 'left', margin: 0 }}><strong>{foro.titulo}</strong></p>
                                      <p style={{ margin: 0 }}>
                                        <Button
                                          className="btn-block forobu direction-button"
                                          onClick={() => handleMostrarForo(foro.idModulos, foro.idForo)}
                                        >
                                          Responder Foro <FaComments className="fa-icon" />
                                        </Button>
                                      </p>
                                    </div>
                                    <hr className='lineamodulo' />
                                  </>
                                ))}
                            </div>
                          ) : <></>}
                        </div>

                      </Collapse>
                    </Fragment>
                  );
                })
              ) : (
                <h3 className="nohaycursos">No hay modulos registrados.</h3>
              )}
            </>
          )}
        </div>
      )}
      <CSSTransition
        in={showPopup}
        timeout={300}
        classNames="popup-transition"
        unmountOnExit
      >
        <Popup open={showPopup} closeOnDocumentClick={false} closeOnEscape={false} className="popup-contenta">
          <div className="popup-wrapper">
            <div className="popup-contenta">
              <div>
                <div className="popup-header">
                  <div className="popup-title">
                    {editarModulo ? "Editar Módulo" : "Agregar Módulo"}
                  </div>
                  <div className="popup-close-button">
                    <FaWindowClose color="#c82333" className="invert-icon" onClick={closePopup} style={{ cursor: 'pointer' }} />
                  </div>
                </div>
                <div className="popup-message">
                  Por favor ingrese los siguientes datos:
                </div>
                <hr />
                <form onSubmit={agregarModulo}>
                  <div className="input-group">
                    <span>Nombre del Módulo: </span>
                    <input
                      id="nombre_modulo"
                      type="text"
                      className="popup-input"
                      placeholder="Escribe el nombre del curso"
                      name="nombre_modulo"
                      value={nuevoModulo.nombre_modulo}
                      maxLength={100}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <span>Enlace Informativo:</span>
                    <input
                      id="enlace_informativo"
                      type="text"
                      className="popup-input"
                      placeholder="www.youtube.com"
                      name="enlace_informativo"
                      maxLength={250}
                      value={nuevoModulo.enlace_informativo}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <span>Enlace a la sesión:</span>
                    <input
                      id="enlace_sesion"
                      type="text"
                      className="popup-input"
                      placeholder="www.googledrive.com"
                      name="enlace_sesion"
                      maxLength={250}
                      value={nuevoModulo.enlace_sesion}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <hr />
                  <div className="input-group" style={{ marginBottom: '12px' }}>
                    <span>Estado:</span>
                    <label className="switch" style={{ marginTop: '5px', marginBottom: '0px', marginLeft: '5px' }}>
                      <input
                        id="estado"
                        type="checkbox"
                        name="estado"
                        checked={nuevoModulo.estado}
                        onChange={() =>
                          setNuevoModulo((prevState) => ({
                            ...prevState,
                            estado: !prevState.estado,
                          }))
                        }
                      />
                      <span className="slider round">
                      </span>
                    </label>
                  </div>
                  <hr style={{ marginTop: '1px' }} />
                  <div className="input-group">
                    <button type="submit" className="btn btn-block mybtn btn-success tx-tfm naranjabtn">
                      {editarModulo ? "Guardar cambios" : "Crear nuevo módulo"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Popup>
      </CSSTransition >
    </Fragment>
  );
}

export default CursoModulo;
