import React, { Fragment, useEffect, useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { FaArrowUp, FaChevronDown, FaChevronRight, FaArrowDown, FaPencilAlt, FaTrash, FaArrowCircleLeft, FaTable, FaPlusSquare, FaLink } from 'react-icons/fa'; // Add FaFile here
import Cookies from 'universal-cookie';
import axios from 'axios';
import Swal from 'sweetalert2';
import CursoTareaCrud from './CursoTareaCrud';
import CursoTareaEnvio from './CursoTareaEnvio'; // Import CursoTareaEnvio component
import { CSSTransition } from 'react-transition-group';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Popup from 'reactjs-popup';
import TareaEditorHTML from './TareaEditorHTML';
import TareaUpdateHTML from './TareaUpdateHTML';
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"

function CursoTarea(props) {

  SessionManager(props);

  const cerrarSesion = () => {
    CerrarSesion(props);
  }
  // const baseUrl = "https://localhost:44322/api/";
  const baseUrl = url;
  const { idCurso } = props;
  const [idModulosOptions, setIdModulosOptions] = useState([]);
  const { idUser } = props;
  const { idPagina } = props;
  const [tareas, setCursos] = useState([]);
  const [showTareas, setShowCrud] = useState(true);
  const [nuevoCursoDocente, setNuevoCursoDocente] = useState([]);
  const [nuevoCursoEstudiante, setNuevoCursoEstudiante] = useState([]);
  const [checkboxesSeleccionados, setCheckboxesSeleccionados] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [soloCurso, setSoloCurso] = useState(true);
  const [nombreClase, setNombreClase] = useState('');
  const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
  const [mostrarEditar, setMostrarUpdate] = useState(false);
  const [nombretareas, setNombreTareas] = useState([]);
  const [notastareas, setNotasTareas] = useState([]);
  const [cursosdocentes, setCursosdocentes] = useState([]);
  const [mostrarTareasCrud, setMostrarTareasCrud] = useState(false);
  const [showCursoForoDiscu, setShowCursoForoDiscu] = useState(false);
  const [mostrarTarea, setMostrarTarea] = useState(false);
  const [mostrarAgregar, setMostrarEditor] = useState(false);
  const [selectedTarea, setSelectedTarea] = useState(null);
  const [idModulo, setIdModulo] = useState(null);
  const [idTarea, setIdTarea] = useState(null);
  const [validacionCompleta, setValidacionCompleta] = useState(false);
  const [showHeader, setShowHeader] = useState(true); // Initialize as true  
  // Estado para controlar si el collapse está abierto o cerrado
  const [open, setOpen] = useState(true);
  // Función para alternar el collapse
  const toggleCollapse = () => {
    setOpen(!open);
  };

  const hastareas = (moduleId) => {
    // Assuming `examenes` contains exam data fetched from your API
    return nombretareas.some((nombretareas) => nombretareas.idTareas === moduleId);
  };



  // Cerrar sesión

  const cookies = new Cookies();

  const RolAlmacenado = cookies.get("rol_activo");
  const usuarioId = parseInt(RolAlmacenado, 10);

  const handleMostrarCrud = () => {
    setMostrarTareasCrud(true);
  };

  const handleOcultarCrud = () => {
    setMostrarTareasCrud(false);
  };

  const redirectToSubCurso = (idTarea) => {
    setSelectedTarea(idTarea);
    setShowCrud(false);
    setShowHeader(false)
    setMostrarTarea(false);
  };

  // Define the functions
  const handleAgregarPagina = (idModulo, idCurso) => {
    setIdModulo(idModulo);
    setSelectedTarea(idCurso);
    setMostrarEditor(true);
  };

  const handleOcultarAgregar = () => {
    setMostrarEditor(false);
  };

  const handleEditarPagina = (idTarea) => {
    setIdTarea(idTarea); // Assuming you have a state variable called idTarea
    setMostrarUpdate(true);
  };

  const handleOcultarPagina = () => {
    setMostrarUpdate(false);
  };

  const handleHacerTarea = (idTarea) => {
    setIdTarea(idTarea);
    setMostrarTarea(true);
  };

  const handleOcultarTarea = () => {
    setMostrarTarea(false);
  };

  const renderSelectedTareaEnvio = () => {
    if (selectedTarea) {
      return <CursoTareaEnvio idCurso={idCurso} idTarea={selectedTarea} />;
    }
    return null;
  };

  /*------------------------------COMPONENTES PARA AGREGAR MODULO-------------------------------------------*/
  const [nuevaTarea, setNuevaTarea] = useState({
    titulo: '',
    descripcion: '',
    fecha_entrega: '',
    nota: '',
    estado: false,
    idModulos: '',
  });

  // Capturando datos para el nuevaTarea.
  const ModuloTarea = {
    titulo: nuevaTarea.titulo,
    descripcion: nuevaTarea.descripcion,
    estado: nuevaTarea.estado,
    nota: parseInt(nuevaTarea.nota),
    fecha_entrega: nuevaTarea.fecha_entrega,
    idModulos: parseInt(nuevaTarea.idModulos),
  };
  const resetForm = () => {
    setNuevaTarea({
      titulo: '',
      descripcion: '',
      fecha_entrega: '',
      nota: '',
      estado: false,
    });
    setNuevoCursoDocente([]);
    setNuevoCursoEstudiante([]);
    setCheckboxesSeleccionados({});
    // Restablecer otras variables relacionadas si las hay
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
    return true;
  };

  // Agregar nuevo Modulo:
  const handleChange = (event) => {
    const { name, value } = event.target;
    // Convertir el valor a entero solo si el campo es 'idModulos' o 'nota'
    const newValue = name === 'idModulos' || name === 'nota' ? parseInt(value) : value;

    setNuevaTarea((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const agregarTarea = async (event) => {
    event.preventDefault(); // Cancelar el evento por defecto
    try {
      if (editarTarea) {
        // EDITAR TAREA
        //
        // Establecer los valores del tarea seleccionado al formulario popup
        //
        // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
        if (
          !nuevaTarea.titulo
        ) {
          throw new Error('Por favor, complete todos los campos.');
        }

        // Verificar si la propiedad hora_inicio está definida, de lo contrario asignar "00:00:00"
        if (!nuevaTarea.hora_inicio) {
          nuevaTarea.hora_inicio = "00:00:00";
        }

        // Verificar si la propiedad hora_fin está definida, de lo contrario asignar "00:00:00"
        if (!nuevaTarea.hora_fin) {
          nuevaTarea.hora_fin = "00:00:00";
        }

        const token = cookies.get('token');
        const response = await axios.post(
          baseUrl + 'tareas/editarTarea',
          nuevaTarea,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Realizar alguna acción después de registrar el tarea exitosamente
        // Por ejemplo, cerrar el popup y volver a cargar la lista de tareas
        if (response.status === 201) {
          Swal.fire({
            icon: 'success',
            title: 'Cambio exitoso',
            text: 'Modulo actualizado correctamente!',
            showConfirmButton: true,
            confirmButtonText: 'Ok'
          });
          togglePopup();
          fetchCursos();
          resetForm();
        }
      } else {
        // AGREGAR TAREA
        //
        // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
        if (
          !nuevaTarea.titulo ||
          !nuevaTarea.descripcion ||
          !nuevaTarea.nota ||
          !nuevaTarea.idModulos
        ) {
          throw new Error('Por favor, complete todos los campos.');
        }
        // Verificar si la propiedad hora_inicio está definida, de lo contrario asignar "00:00:00"
        if (!nuevaTarea.hora_inicio) {
          nuevaTarea.hora_inicio = "00:00:00";
        }
        // Verificar si la propiedad hora_fin está definida, de lo contrario asignar "00:00:00"
        if (!nuevaTarea.hora_fin) {
          nuevaTarea.hora_fin = "00:00:00";
        }
        const token = cookies.get('token');
        if (soloCurso) {

          // Llamar a la API para agregar un tarea, docentes y estudiantes
          const response = await axios.post(
            baseUrl + 'tareas/registrarTarea',
            ModuloTarea,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // Realizar alguna acción después de registrar el tarea exitosamente
          // cerrar el popup y volver a cargar la lista de tareas
          if (response.status === 201) {
            Swal.fire({
              icon: 'success',
              title: 'Registro exitoso',
              text: 'Tarea creado correctamente!',
              showConfirmButton: true,
              confirmButtonText: 'Ok'
            });
            togglePopup();
            fetchCursos();
            resetForm();
          }
        }
      }
    } catch (error) {
      // MANEJO DE ERRORES
      if (error.message === 'Por favor, complete todos los campos.') {
        Swal.fire({
          icon: 'error',
          title: 'Campos incompletos!',
          text: error.message
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Lo sentimos!',
          text: error.message,
        });
      }
    }
  };

  const eliminarTarea = async (idTarea) => {
    Swal.fire({
      title: '¿Estás seguro de que deseas ELIMINAR este modulo?',
      text: 'El modulo y su contenido no se podrán recuperar',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const request = {
            idTarea: idTarea
          };
          const token = cookies.get('token');
          const response2 = await axios.post(baseUrl + 'tareas/eliminarTarea', request, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          });

          // Mostrar Sweet Alert
          Swal.fire({
            icon: 'success',
            title: 'Tarea eliminado correctamente',
            showConfirmButton: true,
            confirmButtonText: 'Ok'
          });
          fetchCursos();

        } catch (error) {
          console.error(error);
          // Manejar el error de la solicitud al backend
        }
      } else {
        setCursoSeleccionado(null); // Restablecer a null
      }
    });
  };

  //

  // Popup Editar
  const [editarTarea, setEditarTarea] = useState(false);

  const abrirEditarTarea = (tarea) => {
    setEditarTarea(true);
    togglePopup();

    // Asignar los valores del tarea seleccionado al objeto nuevaTarea
    setNuevaTarea({
      idTarea: tarea.idTarea,
      titulo: tarea.titulo,
      descripcion: tarea.descripcion,
      nota: parseInt(tarea.nota),
      fecha_entrega: tarea.fecha_entrega,
      idModulos: tarea.idModulos,
      estado: tarea.estado
    });
  };



  /*-------------------------------------------------------------------------------------------------------*/
  const fetchIdModulos = async () => {
    try {
      const token = cookies.get('token');
      const result = await axios.get(baseUrl + "Modulos", {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        // Extraer los idModulos de la respuesta y almacenarlos en el estado
        setIdModulosOptions(result.data);
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };



  //<------------------------------------------MANEJANDO REORGANIZACION------------------------------------------
  // 0
  // Estado para rastrear si se han cargado las tareas
  const [tareasFetched, setTareasFetched] = useState(false);
  const [tareasOrdenadas, setTareasOrdenadas] = useState([]);
  const [alertaMostrada, setAlertaMostrada] = useState(false);

  //1
  const fetchCursos = async () => {
    try {
      const token = cookies.get('token');
      const requestBody = { idCurso: idCurso };
      const result = await axios.post(baseUrl + "tareas/listarTareasMenu", requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        console.log("Respuesta exitosa en fetchExamenes:", result.data);
        setNombreTareas(result.data);
        setTareasFetched(true);
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  // 2
  // Función para manejar la confirmación de movimiento de tareas
  const confirmMoveTarea = (currentIndex, targetIndex) => {
    if (!alertaMostrada) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: '¿Deseas mover esta tarea?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, moverlo',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          // Marcar la alerta como mostrada

          handleMoveTarea(currentIndex, targetIndex);
        }
      });

      // Marcar la alerta como mostrada
      setAlertaMostrada(true);
    } else {
      // Si la alerta ya se mostró antes, simplemente realizar la acción
      handleMoveTarea(currentIndex, targetIndex);
    }
  };

  // 3
  // Función para manejar el movimiento de tareas
  const handleMoveTarea = (currentIndex, targetIndex) => {
    const updatedTareas = [...nombretareas];
    const temp = updatedTareas[currentIndex];
    updatedTareas[currentIndex] = updatedTareas[targetIndex];
    updatedTareas[targetIndex] = temp;

    // Actualizar el estado con los tareas reordenados
    setNombreTareas(updatedTareas);

    // Actualizar el estado de los IDs de los tareas reordenados
    setTareasOrdenadas(updatedTareas.map(tarea => tarea.idTarea));

    // Aquí puedes enviar el nuevo orden al backend o realizar cualquier otra operación necesaria
    guardarOrdenModulos(updatedTareas);
  };

  // 4
  const guardarOrdenModulos = async (updatedTareas) => {
    try {
      const token = cookies.get('token');
      const requestBody = {
        idCurso: idCurso,
        idPagina: idPagina,
        nuevoOrden: updatedTareas.map(tarea => tarea.idTarea).join(",")
      };
      const result = await axios.post(baseUrl + "OrdenModulo/editarOrdenModulos", requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        console.log("Orden de módulos guardado exitosamente");
      } else {
        // Manejar errores o respuestas no exitosas
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  //????
  const ordenarTareasSegunId = (tareasOrdenadas, tareasOriginales) => {
    return tareasOrdenadas.map(idTarea =>
      tareasOriginales.find(tarea => tarea.idTarea === idTarea)
    );
  };

  // 5
  const fetchTareasOrdenadas = async () => {
    try {
      console.log("Ejecutando useEffect para fetchModulosOrdenados...");
      const token = cookies.get('token');
      const requestBody = { idCurso: idCurso, idPagina: 4 };
      const result = await axios.post('https://localhost:44322/api/OrdenModulo/listarModulos', requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        console.log("Respuesta exitosa en fetchModulosOrdenados:", result.data);
        setTareasOrdenadas(result.data);
      } else {
        console.error("Error al obtener los módulos ordenados");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 6
  // Efecto para cargar los tareas al montar el componente o cuando cambia el estado de buttonClicked
  useEffect(() => {
    fetchCursos();
  }, [idCurso]);

  useEffect(() => {
    if (tareasFetched) { // Solo ejecuta si fetchModulos se ha completado
      console.log("Ejecutando useEffect para fetchModulosOrdenados...");
      fetchTareasOrdenadas();
    }
  }, [tareasFetched]);  // Ahora solo depende de modulosFetched
  //<---------------------------------------------------------------------------------------------------------------

  const renderHeader = () => (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {nombreClase.nom_curso && <h5 style={{ margin: '0', marginRight: '10px' }}>{nombreClase.nom_curso}</h5>}
        <FaChevronRight color="#F47735" />
        <h5 style={{ margin: '0', marginLeft: '10px' }}>Portafolio de Tareas</h5>
        <FaChevronRight color="#F47735" />
        <h5 style={{ margin: '0', marginLeft: '10px' }}>Tareas</h5>
      </div>
      <hr />
    </div>
  );

  useEffect(() => {
    const fetchNombreCurso = async () => {
      try {
        const token = cookies.get('token');
        const requestBody = { idCurso: idCurso };
        const result = await axios.post(baseUrl + "cursos/buscarCurso", requestBody, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        if (result.status === 200) {
          setNombreClase(result.data);
        } else {
          // Realizar alguna acción en caso de que la respuesta no sea exitosa
        }
      } catch (error) {
        console.error(error);
        // Manejar el error de la solicitud al backend
      }
    };
    fetchNombreCurso();
    fetchCursos();
    fetchIdModulos();

  }, [idCurso]);

  useEffect(() => {
    // Realiza la validación cuando se actualicen los valores relevantes
    if (nuevaTarea.titulo && nuevaTarea.descripcion && nuevaTarea.fecha_entrega && nuevaTarea.nota) {
      setValidacionCompleta(true);
    } else {
      setValidacionCompleta(false);
    }
  }, [nuevaTarea.titulo, nuevaTarea.descripcion, nuevaTarea.fecha_entrega, nuevaTarea.nota]);

  return (
    <Fragment>
      <div className='container'>
        {showHeader && renderHeader()}
        {/* Show the header if showHeader is true */}
        <div className="d-flex justify-content-end mb-3">
          {usuarioId === 4 || usuarioId === 5 || usuarioId === 7 ? (
            <div className="d-flex justify-content-end mb-3">
              {/* Si no estamos en ninguna vista de CRUD */}
              {!mostrarTareasCrud && !mostrarAgregar && !mostrarEditar && !mostrarTarea && (
                <>
                  <Button className="verdetabla direction-button" onClick={handleMostrarCrud}>
                    Ver tabla de Tareas <FaTable className="fa-icon" />
                  </Button>&nbsp;&nbsp;
                  <Button className="azulbu direction-button" onClick={() => handleAgregarPagina(idCurso, selectedTarea)}>
                    Agregar tarea <FaPlusSquare className="fa-icon" />
                  </Button>
                </>
              )}

              {/* Si estamos en la vista CRUD de tareas */}
              {mostrarTareasCrud && (
                <Button className="tealbu direction-button" onClick={handleOcultarCrud}>
                  Volver a modulos tareas <FaArrowCircleLeft className="fa-icon" />
                </Button>
              )}

              {/* Si estamos en la vista del editor */}
              {mostrarAgregar && (
                <Button className="tealbu direction-button" onClick={handleOcultarAgregar}>
                  Volver a modulos tareas <FaArrowCircleLeft className="fa-icon" />
                </Button>
              )}

              {/* Si estamos en la vista de actualización */}
              {mostrarEditar && (
                <Button className="tealbu direction-button" onClick={handleOcultarPagina}>
                  Volver a modulos tareas <FaArrowCircleLeft className="fa-icon" />
                </Button>
              )}
              {/* Si estamos en la vista de actualización */}
              {mostrarTarea && (
                <Button className="tealbu direction-button" onClick={handleOcultarTarea}>
                  Volver a modulos tareas <FaArrowCircleLeft className="fa-icon" />
                </Button>
              )}
            </div>
          ) : null}

        </div>
        {mostrarTareasCrud ? (
          <CursoTareaCrud idCurso={idCurso} />
        ) : mostrarAgregar ? (
          <TareaEditorHTML idModulos={idCurso} idCurso={idCurso} />
        ) : mostrarEditar ? (
          <TareaUpdateHTML idTarea={idTarea} idCurso={idCurso} />
        ) : mostrarTarea ? (
          <CursoTareaEnvio idCurso={idCurso} idTarea={idTarea} idUser={idUser} />
        ) : showTareas ? (
          <div className='item-group-condensed ig-header'>
            <div className="collapse-header" onClick={toggleCollapse}>
              {open ? <FaChevronDown color="#F47735" /> : <FaChevronRight color="#F47735" />}
              <h4 className="naranjah4">Tareas registradas</h4>
            </div>
            <Collapse in={open}>
              <div className="collapse-content">
                {tareasOrdenadas.length > 0 ? tareasOrdenadas.map((tareaId, index) => {
                  const nomtarea = nombretareas.find(t => t.idTarea === tareaId); // Buscar la tarea por ID
                  if (!nomtarea) return null; // Si no se encuentra la tarea, no renderizar
                  return (
                    <Fragment key={index}>
                      <hr className='lineamodulo' />
                      <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                        {/*{usuarioId === 4 || usuarioId === 5 ? (
                        <div className="d-flex align-items-center" style={{ flexDirection: 'column' }}>                          
                          {index > 0 && (
                            <Button className="boton-arriba blue-outline-button mb-2" onClick={() => confirmMoveTarea(index, index - 1)}>
                              <FaArrowUp />
                            </Button>
                          )}
                          {index < nombretareas.length - 1 && (
                            <Button className="boton-abajo green-outline-button" onClick={() => confirmMoveTarea(index, index + 1)}>
                              <FaArrowDown />
                            </Button>
                          )}
                        </div>
                      ) : null}&nbsp;&nbsp;*/}
                        <span><h4 className="naranjah4 truncate-text-listar-modulo-tarea">{nomtarea.titulo}</h4></span>&nbsp;&nbsp;
                        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                          <span><strong>Puntos: {nomtarea.nota}</strong></span>
                          <div className="d-flex align-items-center justify-content-end">
                            {usuarioId === 4 || usuarioId === 5 || usuarioId === 7 ? (
                              <div className="d-flex align-items-center">
                                <Button className="yellow-outline-button me-2" onClick={() => handleEditarPagina(nomtarea.idTarea)}>
                                  <FaPencilAlt color="#F47735" />
                                </Button>
                                <Button className="red-outline-button me-2" onClick={() => eliminarTarea(nomtarea.idTarea)}>
                                  <FaTrash color="red" />
                                </Button>
                              </div>
                            ) : null}&nbsp;&nbsp;
                            <Button className="tareabu direction-button" onClick={() => handleHacerTarea(nomtarea.idTarea)}>
                              Realizar Tarea <FaLink className="fa-icon" />
                            </Button>
                          </div>
                        </div>
                      </div>
                      <hr className='lineamodulo' />
                    </Fragment>
                  );
                }) : nombretareas.length > 0 ?
                  nombretareas.map((tareasES, index) => {
                    // Aquí va el código para renderizar nomtarea en su orden original...
                    return (
                      <Fragment key={index}>
                        <hr className='lineamodulo' />
                        <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                          {/*{usuarioId === 4 || usuarioId === 5 ? (
                        <div className="d-flex align-items-center" style={{ flexDirection: 'column' }}>                          
                          {index > 0 && (
                            <Button className="boton-arriba blue-outline-button mb-2" onClick={() => confirmMoveTarea(index, index - 1)}>
                              <FaArrowUp />
                            </Button>
                          )}
                          {index < nombretareas.length - 1 && (
                            <Button className="boton-abajo green-outline-button" onClick={() => confirmMoveTarea(index, index + 1)}>
                              <FaArrowDown />
                            </Button>
                          )}
                        </div>
                      ) : null}&nbsp;&nbsp;*/}
                          <h4 className="naranjah4 cambio-truncate-moviles me-2">{tareasES.titulo}</h4>
                          <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                            <span><strong>Puntos: {tareasES.nota}</strong></span>
                            <div className="d-flex align-items-center justify-content-end">
                              {usuarioId === 4 || usuarioId === 5 || usuarioId === 7 ? (
                                <div className="d-flex align-items-center">
                                  <Button className="yellow-outline-button me-2" onClick={() => handleEditarPagina(tareasES.idTarea)}>
                                    <FaPencilAlt color="#F47735" />
                                  </Button>
                                  <Button className="red-outline-button me-2" onClick={() => eliminarTarea(tareasES.idTarea)}>
                                    <FaTrash color="red" />
                                  </Button>
                                </div>
                              ) : null}&nbsp;&nbsp;
                              <Button className="tareabu direction-button" onClick={() => handleHacerTarea(tareasES.idTarea)}>
                                Realizar Tarea <FaLink className="fa-icon" />
                              </Button>
                            </div>
                          </div>
                        </div>
                        <hr className='lineamodulo' />
                      </Fragment>
                    );
                  })
                  :
                  <h3 className="nohaycursos">No hay Tareas registradas.</h3>
                }
              </div>
            </Collapse>
            <br />
          </div>
        ) : (
          renderSelectedTareaEnvio()
        )}
      </div>
      <CSSTransition
        in={showPopup}
        timeout={300}
        classNames="popup-transition"
        unmountOnExit
      >
        <Popup open={showPopup} closeOnDocumentClick className="popup-contenta">
          <div className="popup-wrapper">
            <div className="popup-contenta">
              <Tabs forceRenderTabPanel>
                <TabList>
                  <Tab>Agregar Tarea</Tab>
                </TabList>

                <TabPanel>
                  <div className="popup-header">
                    Agregar Tarea
                  </div>
                  <hr />
                  <div className="popup-message">
                    Por favor ingrese los siguientes datos:
                  </div>
                  <br />
                  <form onSubmit={agregarTarea}>
                    <div className="input-group">
                      <span>Titulo de la tarea: </span>
                      <input
                        id="titulo"
                        type="text"
                        className="popup-input"
                        placeholder="Escribe el nombre del Tarea"
                        name="titulo"
                        value={nuevaTarea.titulo}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="input-group">
                      <span>Descripción de la Tarea: </span>
                      <input
                        id="descripcion"
                        type="text"
                        className="popup-input"
                        placeholder="Escribe una descripcion de la Tarea"
                        name="descripcion"
                        value={nuevaTarea.descripcion}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="input-group">
                      <span>Nota:</span>
                      <input
                        id="nota"
                        type="text"
                        className="popup-input"
                        placeholder="19"
                        name="nota"
                        value={nuevaTarea.nota}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <hr />
                    <div className="input-group">
                      <span>Fecha de entrega:</span>
                      <input
                        id="fecha_entrega"
                        type="text"
                        className="popup-input"
                        placeholder="2023-08-21"
                        name="fecha_entrega"
                        value={nuevaTarea.fecha_entrega}
                        onChange={handleChange}
                      />
                    </div>
                    <hr />
                    <div className="input-group">
                      <span>Id Modulos:</span>
                      <select
                        id="idModulos"
                        className="popup-input"
                        name="idModulos"
                        value={nuevaTarea.idModulos}
                        onChange={handleChange}
                        required
                      >
                        {/* Opción predeterminada del combobox */}
                        <option value="">Seleccionar Id Modulos</option>
                        {/* Mapear los idModulos disponibles en el estado */}
                        {idModulosOptions.map((idModulo) => (
                          <option key={idModulo.idModulos} value={idModulo.idModulos}>
                            {idModulo.nombre_modulo}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="input-group">
                      <span>Estado:</span>
                      <label className="switch">
                        <input
                          id="estado"
                          type="checkbox"
                          name="estado"
                          checked={nuevaTarea.estado}
                          onChange={() =>
                            setNuevaTarea((prevState) => ({
                              ...prevState,
                              estado: !prevState.estado,
                            }))
                          }
                        />
                        <span className="slider round">
                        </span>
                      </label>
                    </div>
                    <hr style={{ marginTop: '1px' }} />
                    <div className="input-group">
                      <button type="submit" className="btn btn-block mybtn btn-success tx-tfm naranjabtn">
                        {editarTarea ? "Guardar cambios" : "Crear nueva tarea"}
                      </button>
                    </div>
                  </form>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </Popup>
      </CSSTransition>
    </Fragment>
  );
}

export default CursoTarea;