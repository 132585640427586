import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
import axios from "axios";
import { url } from "../config";
import Swal from "sweetalert2";

const SessionManager = (props) => {

    useEffect(() => {
        // Detectar la resolución del monitor
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;

        // Ajustar el zoom según la resolución
        if (screenWidth <= 1352 || screenHeight <= 768 || screenWidth <= 1366 || screenHeight <= 768) {
            document.body.style.zoom = '75%';
        }
    }, []);
    const baseUrl = url;
    const cookies = new Cookies();
    const history = useHistory();

    // Session Management
    //
    // Función cerrar sesión  
    //const cerrarSesion = () => {
    //  cookies.remove("token", { path: "/" });
    //  props.history.push("./");
    //};



    const cerrarSesion = async (props) => {
        try {
            const token = cookies.get("token");
            if (token) {
                const decodedToken = jwt_decode(token);
                const currentTimestamp = Math.floor(Date.now() / 1000);

                if (decodedToken.exp >= currentTimestamp) {
                    // Token válido, intenta enviar solicitud de restablecimiento
                    const resultado = await axios.post(
                        baseUrl + "usuarios/restablecer",
                        {},
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                }

                // Elimina el token y redirige al usuario a la pantalla de inicio de sesión
                cookies.remove("token", { path: "/" });
                cookies.remove("rol_activo", { path: "/" });
                props.history.push("./");
            }
        } catch (error) {
            console.error(error);
            // Manejar el error de la solicitud al backend
        }
    };

    const cerrarSesionSinToken = async () => {
        cookies.remove("token", { path: "/" });
        cookies.remove("rol_activo", { path: "/" });
        history.push("./");
    };

    // Agrega un estado para mantener el tiempo de inactividad
    const [inactiveTime, setInactiveTime] = useState(0);

    // Define una función para restablecer el temporizador de inactividad
    const resetInactiveTimer = () => {
        setInactiveTime((prevTime) => {
            // Hacer algo adicional si es necesario antes de reiniciar el temporizador
            return 0; // Reiniciar el temporizador
        });
    };


    useEffect(() => {
        if (!cookies.get("token")) {
            history.push("./");
        }

        const checkTokenExpiration = () => {
            const token = cookies.get("token");
            if (token) {
                const decodedToken = jwt_decode(token);
                const currentTimestamp = Math.floor(Date.now() / 1000); // Obtener la fecha y hora actual en segundos

                // Verificar si el usuario ha estado inactivo durante 15 minutos
                if (inactiveTime >= 900) {
                    // Redirigir al usuario a la pantalla de inicio de sesión
                    cerrarSesion();
                } else if (decodedToken.exp < currentTimestamp) {
                    // El token ha expirado, cerrar sesión
                    cerrarSesion();
                }
            } else {
                // No hay token, cerrar sesión
                cerrarSesionSinToken();
            }
        };

        checkTokenExpiration();

        // Iniciar el temporizador de inactividad cuando el componente se monta
        const inactiveTimer = setInterval(() => {
            setInactiveTime((prevTime) => prevTime + 1);

            // Mostrar SweetAlert cuando falte 1 minuto
            if (inactiveTime === 14 * 60) {
                Swal.fire({
                    title: "Aún estás ahí?",
                    text: "En un minuto se cerrará tu sesión.",
                    icon: "info",
                    //showCancelButton: true,
                    //cancelButtonText: "Cerrar sesión",
                    confirmButtonColor: "#28A745",
                    confirmButtonText: "Seguir en la página",
                    timer: 59000,
                }).then((result) => {
                    if (result.isConfirmed) {
                        // El usuario eligió "Seguir en la página", reinicia el temporizador
                        resetInactiveTimer();
                    } else {
                        // El usuario eligió "Cerrar sesión", cierra la sesión
                        cerrarSesion();
                    }
                });
            }
        }, 1000); // Aumentar el tiempo de inactividad cada segundo

        // Agregar event listeners para restablecer el temporizador en actividad
        window.addEventListener('mousemove', resetInactiveTimer);
        window.addEventListener('keypress', resetInactiveTimer);

        // Limpiar el temporizador y los event listeners cuando el componente se desmonta
        return () => {
            clearInterval(inactiveTimer);
            window.removeEventListener('mousemove', resetInactiveTimer);
            window.removeEventListener('keypress', resetInactiveTimer);
        };
    }, [cookies, history, inactiveTime]);

    return (<></>);
};

export default SessionManager;