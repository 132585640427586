import React, { Fragment, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { FaArrowCircleLeft} from "react-icons/fa";
import axios from 'axios';
import { withRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import CursoEditorHTML from './CursoEditorHTML';
import CursoUpdateHTML from './CursoUpdateHTML';
import { Button, Table, Container } from 'react-bootstrap';
import { FaPlus, FaPencilAlt } from 'react-icons/fa';
import 'react-calendar/dist/Calendar.css';
import jwt_decode from 'jwt-decode';
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"

function CursoInicio(props) {
    SessionManager(props);

    const cerrarSesion = () => {
        CerrarSesion(props);
    }
    // const baseUrl = "https://localhost:44322/api/";
    const baseUrl = url;
    const { contenido } = props;
    const { idCurso } = props;
    const { idUser } = props;
    const [nombreClase, setNombreClase] = useState('');
    const [mostrarEditor, setMostrarEditor] = useState(false);
    const [mostrarUpdate, setMostrarUpdate] = useState(false);
    const [showEditor, setShowEditor] = useState(true);
    const [editorContent, setEditorContent] = useState('');
    const [idUsuario, setIdUsuario] = useState([]);

    const handleocultarUpdate = () => {
        setMostrarUpdate(false);
    };

    const handlemostrarUpdate = () => {
        setMostrarUpdate(true);
    };

    // 
    // Cerrar sesión


    const cookies = new Cookies();


    const renderStyledContent = () => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(editorContent.contenido, 'text/html');
        const elementsWithTables = doc.querySelectorAll('table');

        // Apply styles to the elements with tables, for example, add a class
        elementsWithTables.forEach((table) => {
            // You can add a class to style these tables
            table.classList.add('custom-table-html');
            // Add custom styling to tables
            table.style.width = '100%'; // Set the table width to 100% of the container
            table.style.borderCollapse = 'collapse'; // Ensure cells have borders that separate them
            // Set the table layout to fixed for equal column widths
            table.style.tableLayout = 'fixed';
            // Select all table cells
            const cells = table.querySelectorAll('td');
            // Divide the available width equally among all cells
            const cellWidth = `${100 / cells.length}%`;
            cells.forEach((cell) => {
                cell.style.width = cellWidth;
            });
        });

        return <div className='justificando' dangerouslySetInnerHTML={{ __html: doc.documentElement.innerHTML }}></div>;
    };

    const fetchUserId = async () => {
        try {
            const token = cookies.get('token');
            const response = await axios.get(baseUrl + "usuarios/userdata", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const userData = response.data;
            setIdUsuario(userData.rolesId);
        } catch (error) {
            console.error(error);
            // Maneja el error de la solicitud al backend
        }
    };

    const RolAlmacenado = cookies.get("rol_activo");
    const usuarioId = parseInt(RolAlmacenado, 10);
    const userId = parseInt(RolAlmacenado, 10);

    useEffect(() => {


        const fetchNombreCurso = async () => {
            try {
                const token = cookies.get('token');
                const requestBody = { idCurso: idCurso };
                const result = await axios.post(baseUrl + "cursos/buscarCurso", requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (result.status === 200) {
                    setNombreClase(result.data);
                } else {
                    // Realizar alguna acción en caso de que la respuesta no sea exitosa
                }
            } catch (error) {
                console.error(error);
                // Manejar el error de la solicitud al backend
            }
        };


        const fetchEditorHTML = async () => {
            try {
                const token = cookies.get('token');
                const requestBody = { idCurso: idCurso };
                const result = await axios.post(baseUrl + "Paginas_contenido/BuscarPaginas_contenidoCurso", requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (result.status === 200) {
                    setEditorContent(result.data);
                } else {
                    // Manejar el caso en que la solicitud no sea exitosa
                }
            } catch (error) {
                console.error(error);
                // Manejar el error de la solicitud al backend
            }
        };
        fetchUserId();
        fetchNombreCurso();
        fetchEditorHTML();

    }, [idCurso]);

    return (
        <Fragment>
            <Container className="custom-container my-5 p-3 bg-light border rounded">

                {usuarioId === 4 || usuarioId === 5 || usuarioId === 7 ? (
                    <div className="d-flex justify-content-end arribitanomas">
                        <Button className="amarillobu" onClick={handlemostrarUpdate}>
                            Editar pagina <FaPencilAlt />
                        </Button>
                    </div>
                ) : null}
                {mostrarEditor ? (
                    <CursoEditorHTML idCurso={idCurso} />
                ) : mostrarUpdate ? (
                    <>
                        <div className="d-flex justify-content-end">
                            <Button className="tealbu direction-button" onClick={handleocultarUpdate}>
                                Volver lista de Módulos <FaArrowCircleLeft className="fa-icon" />
                            </Button>
                        </div>
                        <CursoUpdateHTML idCurso={idCurso} />
                    </>
                ) : (
                    <div className="container">
                        <h2 className='titulo-inicio'>{nombreClase.nom_curso}</h2>
                        <hr />
                        {/* Render the content with styled tables */}
                        {editorContent && editorContent.contenido && renderStyledContent()}
                        <hr />
                    </div>
                )}
            </Container>
        </Fragment>
    );
}

export default withRouter(CursoInicio);