import React, { Fragment, useEffect, useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import {
  FaPlus,
  FaChevronDown,
  FaArrowDown,
  FaArrowUp,
  FaComment,
  FaPencilAlt,
  FaTrash,
  FaFile,
  FaChevronRight,
  FaTable,
  FaArrowCircleLeft,
  FaPlusSquare,
  FaPencilRuler
} from "react-icons/fa";
import Cookies from "universal-cookie";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import { CSSTransition } from 'react-transition-group';
import CursoModulosCrud from "./CursoModuloCrud";
import CursoExamenCrud from "./CursoExamenCrud";
import ExamenPreguntas from "./CursoExamenes/ExamenPreguntas";
import ExamenPrevResolution from "./CursoExamenes/ExamenPrevResolution";
import CursoForoDiscu from "./CursoForoDiscu";
import ExamenVPrevia from "./CursoExamenes/ExamenVPrevia";
import ExamenEditorHTML from "./ExamenEditorHTML";
import ExamenUpdateHTML from "./ExamenUpdateHTML";
import { url } from "../../../../../config";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js"

function CursoExamen(props) {

  SessionManager(props);

  const cerrarSesion = () => {
    CerrarSesion(props);
  }
  const baseUrl = url;
  const { idCurso } = props;
  const { idPagina } = props;
  const { idUser } = props;
  const [collapse1, setCollapse1] = useState(false);
  const [collapse2, setCollapse2] = useState(false);
  const [collapse3, setCollapse3] = useState(false);
  const [collapse4, setCollapse4] = useState(false);
  const [examenes, setCursos] = useState([]);
  const [nombremodulos, setNombreModulos] = useState([]);
  const [idModulosOptions, setIdModulosOptions] = useState([]);
  const [nombreClase, setNombreClase] = useState("");
  const [mostrarCrud, setMostrarCrud] = useState(false);
  const [showCrud, setShowCrud] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [cursoSeleccionado, setCursoSeleccionado] = useState(null);
  const [nuevoCursoDocente, setNuevoCursoDocente] = useState([]);
  const [nuevoCursoEstudiante, setNuevoCursoEstudiante] = useState([]);
  const [checkboxesSeleccionados, setCheckboxesSeleccionados] = useState({});
  const [selectedExamen, setSelectedExamen] = useState(null);
  const [selectedPrevia, setSelectedPrevia] = useState(null);
  const [soloCurso, setSoloCurso] = useState(true);
  const [validacionCompleta, setValidacionCompleta] = useState(false);
  const [selectedForum, setSelectedForum] = useState(null);
  const [showCursoForoDiscu, setShowCursoForoDiscu] = useState(false);
  const [foros, setForos] = useState([]);
  const [mostrarEditor, setMostrarEditor] = useState(false);
  const [mostrarUpdate, setMostrarUpdate] = useState(false);
  const [idModulo, setIdModulo] = useState(null);

  const [examenesOrdenados, setExamenesOrdenados] = useState([]);

  const [alertaMostrada, setAlertaMostrada] = useState(false);


  const [mostrarTarea, setMostrarTarea] = useState(false);
  const [idTarea, setIdTarea] = useState(null);
  const [idExamen, setIdExamen] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(
    localStorage.getItem("buttonClicked") === "true");
  // Estado para controlar si el collapse está abierto o cerrado
  const [open, setOpen] = useState(true);
  // Estado para rastrear el índice del collapse abierto
  const [openCollapseIndex, setOpenCollapseIndex] = useState(null);

  const cookies = new Cookies();


  const hasExams = (moduleId) => {
    // Assuming `examenes` contains exam data fetched from your API
    return examenes.some((examen) => examen.idModulos === moduleId);
  };

  const hasExamen = (moduleId) => {
    // Assuming `examenes` contains exam data fetched from your API
    return nombremodulos.some((nomtarea) => nomtarea.idModulos === moduleId);
  };

  // Define the functions
  const handleAgregarPagina = (idModulo) => {
    setIdModulo(idModulo);
    setMostrarEditor(true);
  };

  // Define the functions
  const handleOcultarAgregarPagina = () => {
    setMostrarEditor(false);
  };

  const handleEditarPagina = (idExamen) => {
    setIdExamen(idExamen); // Assuming you have a state variable called idExamen
    setMostrarUpdate(true); // This will trigger the render of TareaUpdateHTML
  };

  const handleOcultarEditarPagina = () => {
    setMostrarUpdate(false); // This will trigger the render of TareaUpdateHTML
  };

  const handleMostrarCrud = () => {
    setMostrarCrud(true);
  };

  const handleOcultarCrud = () => {
    setMostrarCrud(false);
  };

  const toggleCollapse = () => {
    setOpen(!open);
  };

  const handleHacerTarea = (idExamen) => {
    setIdExamen(idExamen);
    setMostrarTarea(true);
  };

  const handleNoHacerTarea = () => {
    setMostrarTarea(false);
  };

  const handleButtonClick = async (examen) => {
    if (!buttonClicked) {
      const idExamen = examen.idExamen;
      redirectToVPrevia(idExamen);
      await registerExamResponse(idExamen);
      setButtonClicked(true);
    }
  };
  const navigateToCursoModulo = () => {
    // Reset any state or conditions as needed
    setShowCrud(true);
    setSelectedPrevia(null);
  };




  const fetchForos = async () => {
    try {
      const token = cookies.get("token");
      const requestBody = { idCurso: idCurso };
      const result = await axios.post(
        baseUrl + "Foros/listarForosMenu",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) {
        setForos(result.data);
      } else {
        // Handle the response if it's not successful
      }
    } catch (error) {
      console.error(error);
      // Handle the error of the request to the backend
    }
  };
  /*------------------------------COMPONENTES PARA AGREGAR MODULO-------------------------------------------*/
  const [nuevoExamen, setNuevoExamen] = useState({
    titulo: "",
    descripcion: "",
    adjunto: "",
    puntos: "",
    estado: false,
    idModulos: "",
  });

  // Capturando datos para el nuevoExamen.
  const ModuloExamen = {
    titulo: nuevoExamen.titulo,
    descripcion: nuevoExamen.descripcion,
    estado: nuevoExamen.estado,
    puntos: parseInt(nuevoExamen.puntos),
    adjunto: nuevoExamen.adjunto,
    idModulos: parseInt(nuevoExamen.idModulos),
  };

  const resetForm = () => {
    setNuevoExamen({
      titulo: "",
      descripcion: "",
      adjunto: "",
      puntos: "",
      estado: false,
    });
    setNuevoCursoDocente([]);
    setNuevoCursoEstudiante([]);
    setCheckboxesSeleccionados({});
    // Restablecer otras variables relacionadas si las hay
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
    return true;
  };

  const fetchCursos = async () => {
    try {
      const token = cookies.get("token");
      const requestBody = { idCurso: idCurso };
      const result = await axios.post(
        baseUrl + "examen/listarExamenesMenu",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) {
        setCursos(result.data);
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  // Agregar nuevo Modulo:
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Convertir el valor a entero solo si el campo es 'idModulos' o 'nota'
    const newValue =
      name === "idModulos" || name === "puntos" ? parseInt(value) : value;

    setNuevoExamen((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const agregarExamen = async (event) => {
    event.preventDefault(); // Cancelar el evento por defecto
    try {
      if (editarExamen) {
        // EDITAR Examen
        //
        // Establecer los valores del examen seleccionado al formulario popup
        //
        // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
        if (
          !nuevoExamen.titulo ||
          !nuevoExamen.descripcion ||
          !nuevoExamen.puntos ||
          !nuevoExamen.idModulos
        ) {
          throw new Error("Por favor, complete todos los campos.");
        }

        // Verificar si la propiedad hora_inicio está definida, de lo contrario asignar "00:00:00"
        if (!nuevoExamen.hora_inicio) {
          nuevoExamen.hora_inicio = "00:00:00";
        }

        // Verificar si la propiedad hora_fin está definida, de lo contrario asignar "00:00:00"
        if (!nuevoExamen.hora_fin) {
          nuevoExamen.hora_fin = "00:00:00";
        }

        const token = cookies.get("token");
        const response = await axios.post(
          baseUrl + "examen/editarExamen",
          nuevoExamen,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Realizar alguna acción después de registrar el examen exitosamente
        // Por ejemplo, cerrar el popup y volver a cargar la lista de examenes
        if (response.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Cambio exitoso",
            text: "Modulo actualizado correctamente!",
            showConfirmButton: true,
            confirmButtonText: "Ok",
          });
          togglePopup();
          fetchCursos();
          resetForm();
        }
      } else {
        // AGREGAR Examen
        //
        // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
        if (
          !nuevoExamen.titulo ||
          !nuevoExamen.descripcion ||
          !nuevoExamen.puntos ||
          !nuevoExamen.idModulos
        ) {
          throw new Error("Por favor, complete todos los campos.");
        }
        // Verificar si la propiedad hora_inicio está definida, de lo contrario asignar "00:00:00"
        if (!nuevoExamen.hora_inicio) {
          nuevoExamen.hora_inicio = "00:00:00";
        }
        // Verificar si la propiedad hora_fin está definida, de lo contrario asignar "00:00:00"
        if (!nuevoExamen.hora_fin) {
          nuevoExamen.hora_fin = "00:00:00";
        }
        const token = cookies.get("token");
        if (soloCurso) {

          // Llamar a la API para agregar un examen, docentes y estudiantes
          const response = await axios.post(
            baseUrl + "examen/registrarExamen",
            ModuloExamen,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // Realizar alguna acción después de registrar el examen exitosamente
          // cerrar el popup y volver a cargar la lista de examenes
          if (response.status === 201) {
            Swal.fire({
              icon: "success",
              title: "Registro exitoso",
              text: "Examen creado correctamente!",
              showConfirmButton: true,
              confirmButtonText: "Ok",
            });
            togglePopup();
            fetchCursos();
            resetForm();
          }
        }
      }
    } catch (error) {
      // MANEJO DE ERRORES
      if (error.message === "Por favor, complete todos los campos.") {
        Swal.fire({
          icon: "error",
          title: "Campos incompletos!",
          text: error.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Lo sentimos!",
          text: error.message,
        });
      }
    }
  };

  const eliminarExamen = async (idExamen) => {
    Swal.fire({
      title: "¿Estás seguro de que deseas ELIMINAR este modulo?",
      text: "El modulo y su contenido no se podrán recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const request = {
            idExamen: idExamen,
          };
          const token = cookies.get("token");
          const response2 = await axios.post(
            baseUrl + "examen/eliminarExamen",
            request,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Mostrar Sweet Alert
          Swal.fire({
            icon: "success",
            title: "Examen eliminado correctamente",
            showConfirmButton: true,
            confirmButtonText: "Ok",
          });
          fetchCursos();
        } catch (error) {
          console.error(error);
          // Manejar el error de la solicitud al backend
        }
      } else {
        setCursoSeleccionado(null); // Restablecer a null
      }
    });
  };

  //

  // Popup Editar
  const [editarExamen, setEditarExamen] = useState(false);

  const abrirEditarExamen = (examen) => {
    setEditarExamen(true);
    togglePopup();

    // Asignar los valores del examen seleccionado al objeto nuevoExamen
    setNuevoExamen({
      idExamen: examen.idExamen,
      titulo: examen.titulo,
      descripcion: examen.descripcion,
      puntos: parseInt(examen.puntos),
      adjunto: examen.adjunto,
      idModulos: examen.idModulos,
      estado: examen.estado,
    });
  };

  const fetchIdModulos = async () => {
    try {
      const token = cookies.get("token");
      const result = await axios.get(baseUrl + "Modulos", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (result.status === 200) {
        // Extraer los idModulos de la respuesta y almacenarlos en el estado
        setIdModulosOptions(result.data);
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  const redirectToSubCurso = (idExamen) => {
    setSelectedExamen(idExamen);
    setShowCrud(false);
  };

  const redirectToVPrevia = (idExamen) => {
    setSelectedPrevia(idExamen);
    setShowCrud(false);
  };

  const renderSelectedExamen = () => {
    if (selectedExamen) {
      return <ExamenPreguntas idCurso={idCurso} idExamen={selectedExamen} />;
    }
    return null;
  };

  const renderVPrevia = () => {
    if (selectedPrevia) {
      return <ExamenVPrevia idCurso={idCurso} idExamen={selectedPrevia} />;
    }
    return null;
  };




  /*-------------------------------------------------------------------------------------------------------*/
  // Cerrar sesión


  // Function to register exam response
  const registerExamResponse = async (idExamen) => {
    try {
      const token = cookies.get("token");
      const requestBody = { idExamen: idExamen }; // Use the idExamen passed as a parameter
      const response = await axios.post(
        baseUrl + "Examen_respuesta/registrarExamen_respuesta",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        // Exam response registration was successful, handle the response as needed
      } else {
        // Handle errors or show a message to the user
      }
    } catch (error) {
      console.error(error);
      // Handle errors or show a message to the user
    }
  };

  const RolAlmacenado = cookies.get("rol_activo");
  const usuarioId = parseInt(RolAlmacenado, 10);

  

  useEffect(() => {
    const fetchNombreCurso = async () => {
      try {
        const token = cookies.get("token");
        const requestBody = { idCurso: idCurso };
        const result = await axios.post(
          baseUrl + "cursos/buscarCurso",
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.status === 200) {
          setNombreClase(result.data);
        } else {
          // Realizar alguna acción en caso de que la respuesta no sea exitosa
        }
      } catch (error) {
        console.error(error);
        // Manejar el error de la solicitud al backend
      }
    };

    fetchNombreCurso();
    fetchIdModulos();
    fetchCursos();


    if (buttonClicked) {
      localStorage.setItem("buttonClicked", "true");
    }
  }, [buttonClicked, idCurso]);

  //<------------------------------------------MANEJANDO REORGANIZACION--------------------------------------------------------------
  // Estado para rastrear si se han cargado los exámenes
  const [examenesFetched, setExamenesFetched] = useState(false);

  // Función para cargar los exámenes desde el backend
  const fetchExamenes = async () => {
    try {
      console.log("Iniciando fetchExamenes...");
      const token = cookies.get('token');
      const requestBody = { idCurso: idCurso };
      const result = await axios.post(baseUrl + "examen/listarExamenesMenu", requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (result.status === 200) {
        console.log("Respuesta exitosa en fetchExamenes:", result.data);
        // Establecer el estado de todos los exámenes
        setCursos(result.data);
        setExamenesFetched(true);

        // Aquí puedes realizar cualquier otra operación necesaria con los exámenes cargados
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  const ordenarExamenesSegunId = (examenesOrdenados, examenesOriginales) => {
    return examenesOrdenados.map(idExamen =>
      examenesOriginales.find(examen => examen.idExamen === idExamen)
    );
  };


  // Función para manejar el movimiento de exámenes
  const handleMoveExamen = (currentIndex, targetIndex) => {
    const updatedExams = [...examenes];
    const temp = updatedExams[currentIndex];
    updatedExams[currentIndex] = updatedExams[targetIndex];
    updatedExams[targetIndex] = temp;

    // Actualizar el estado con los exámenes reordenados
    setCursos(updatedExams);

    // Actualizar el estado de los IDs de los exámenes reordenados
    setExamenesOrdenados(updatedExams.map(examen => examen.idExamen));

    // Aquí puedes enviar el nuevo orden al backend o realizar cualquier otra operación necesaria
    guardarOrdenExamen(updatedExams);
  };


  // Función para guardar el orden de los exámenes en el backend
  const guardarOrdenExamen = async (updatedExams) => {
    try {
      const token = cookies.get('token');
      const requestBody = {
        idCurso: idCurso,
        idPagina: idPagina,  // Asegúrate de tener la variable idPagina definida en el contexto correcto
        nuevoOrden: updatedExams.map(examen => examen.idExamen).join(",")
      };
      const result = await axios.post(baseUrl + "OrdenModulo/editarOrdenModulos", requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (result.status === 200) {
        console.log("Orden de exámenes guardado exitosamente");
      } else {
        // Manejar errores o respuestas no exitosas
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  // Función para manejar la confirmación de movimiento de exámenes
  const confirmMoveExamen = (currentIndex, targetIndex) => {
    if (!alertaMostrada) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: '¿Deseas mover este examen?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, moverlo',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          handleMoveExamen(currentIndex, targetIndex);
        }
      });

      // Marcar la alerta como mostrada
      setAlertaMostrada(true);
    } else {
      // Si la alerta ya se mostró antes, simplemente realizar la acción
      handleMoveExamen(currentIndex, targetIndex);
    }
  };

  const fetchModulosOrdenados = async () => {
    try {
      console.log("Ejecutando useEffect para fetchModulosOrdenados...");
      const token = cookies.get('token');
      const requestBody = { idCurso: idCurso, idPagina: 5 };
      const result = await axios.post('https://localhost:44322/api/OrdenModulo/listarModulos', requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        console.log("Respuesta exitosa en fetchModulosOrdenados:", result.data);
        setExamenesOrdenados(result.data);
      } else {
        console.error("Error al obtener los módulos ordenados");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Efecto para cargar los exámenes al montar el componente o cuando cambia el estado de buttonClicked
  useEffect(() => {
    fetchExamenes();
  }, [buttonClicked, idCurso]);

  useEffect(() => {
    if (examenesFetched) { // Solo ejecuta si fetchModulos se ha completado
      console.log("Ejecutando useEffect para fetchModulosOrdenados...");
      fetchModulosOrdenados();
    }
  }, [examenesFetched]);  // Ahora solo depende de modulosFetched

  //<--------------------------------------------------------------------------------------------------------

  useEffect(() => {
    // Realiza la validación cuando se actualicen los valores relevantes
    if (
      nuevoExamen.titulo &&
      nuevoExamen.descripcion &&
      nuevoExamen.adjunto &&
      nuevoExamen.puntos
    ) {
      setValidacionCompleta(true);
    } else {
      setValidacionCompleta(false);
    }
  }, [
    nuevoExamen.titulo,
    nuevoExamen.descripcion,
    nuevoExamen.adjunto,
    nuevoExamen.puntos,
  ]);

  return (
    <Fragment>
      {renderSelectedExamen()}
      {renderVPrevia()}
      {!selectedExamen && showCrud && (
        <div className="container">
          <div style={{ display: "flex", alignItems: "center" }}>
            {nombreClase.nom_curso && (
              <h5 style={{ margin: "0", marginRight: "10px" }}>
                {nombreClase.nom_curso}
              </h5>
            )}
            <FaChevronRight color="#F47735" />
            <h5 style={{ margin: "0", marginLeft: "10px" }}>
              Portafolio de Exámenes
            </h5>
            <FaChevronRight color="#F47735" />
            <h5 style={{ margin: "0", marginLeft: "10px" }}>Exámenes</h5>
          </div>
          <hr />
          <div className="d-flex justify-content-end mb-1">
            {usuarioId === 4 || usuarioId === 5 || usuarioId === 7 ? (
              <div className="d-flex justify-content-end">
                {/* Si no estamos en ninguna vista de CRUD */}
                {!mostrarCrud && !mostrarEditor && !mostrarUpdate && !mostrarTarea && (
                  <>
                    <Button className="verdetabla direction-button" onClick={handleMostrarCrud}>
                      Ver tabla de Exámenes <FaTable className="fa-icon" />
                    </Button>
                    &nbsp;&nbsp;
                    <Button className="azulbu direction-button"
                      onClick={() => handleAgregarPagina(foros.idModulos)}
                    >
                      Agregar Examen <FaPlusSquare className="fa-icon" />
                    </Button>
                  </>

                )}
                {/* Si estamos en la vista CRUD de tareas */}
                {mostrarCrud && (
                  <Button className="tealbu direction-button" onClick={handleOcultarCrud}>
                    Volver a modulos exámenes <FaArrowCircleLeft className="fa-icon" />
                  </Button>
                )}

                {/* Si estamos en la vista del editor */}
                {mostrarEditor && (
                  <Button className="tealbu direction-button" onClick={handleOcultarAgregarPagina}>
                    Volver a modulos exámenes <FaArrowCircleLeft className="fa-icon" />
                  </Button>
                )}

                {/* Si estamos en la vista de actualización */}
                {mostrarUpdate && (
                  <Button className="tealbu direction-button" onClick={handleOcultarEditarPagina}>
                    Volver a modulos exámenes <FaArrowCircleLeft className="fa-icon" />
                  </Button>
                )}
                {/* Si estamos en la vista de actualización */}
                {mostrarTarea && (
                  <Button className="tealbu direction-button" onClick={handleNoHacerTarea}>
                    Volver a modulos exámenes <FaArrowCircleLeft className="fa-icon" />
                  </Button>
                )}

              </div>
            ) : null}

          </div>
          {mostrarCrud ? (
            <CursoExamenCrud idCurso={idCurso} />
          ) : mostrarEditor ? (
            <ExamenEditorHTML idModulos={idCurso} />
          ) : mostrarUpdate ? (
            <ExamenUpdateHTML idExamen={idExamen} idModulos={idCurso} />
          ) : mostrarTarea ? (
            <ExamenPrevResolution idExamen={idExamen} idCurso={idCurso} />
          ) : (
            <div className="item-group-condensed ig-header">
              <div className='item-group-condensed ig-header'>
                <div className="collapse-header" onClick={toggleCollapse}>
                  {open ? <FaChevronDown color="#F47735" /> : <FaChevronRight color="#F47735" />}
                  <h4 className="naranjah4">Exámenes registrados</h4>
                </div>
                <Collapse in={open}>
                  <div className="collapse-content">
                    {examenesOrdenados.length > 0 ? examenesOrdenados.map((examenId, index) => {
                      const examen = examenes.find(e => e.idExamen === examenId); // Buscar el examen por ID
                      if (!examen) return null; // Si no se encuentra el examen, no renderizar
                      return (
                        <Fragment key={index}>
                          <hr className='lineamodulo' />
                          <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                            {/*BOTONES DE REORDENAMIENTO*/}
                            {/* {usuarioId === 4 || usuarioId === 5 ? (
                                <div className="d-flex align-items-center ahorita-no" style={{ flexDirection: 'column' }}>
                                  
                                  {index > 0 && (
                                    <Button className="boton-arriba blue-outline-button mb-2" onClick={() => confirmMoveExamen(index, index - 1)}>
                                      <FaArrowUp />
                                    </Button>
                                  )}
                                  {index < examenes.length - 1 && (
                                    <Button className="boton-abajo green-outline-button" onClick={() => confirmMoveExamen(index, index + 1)}>
                                      <FaArrowDown />
                                    </Button>
                                  )}
                                </div>
                              ) : null}&nbsp;&nbsp;  */}
                            <span><h4 className="naranjah4 truncate-text-listar-modulo-tarea">{examen.titulo}</h4></span>&nbsp;&nbsp;
                            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                              <span><strong>Puntos: {examen.puntos}</strong></span>
                              <div className="d-flex align-items-center justify-content-end">
                                {usuarioId === 4 || usuarioId === 5 || usuarioId === 7 ? (
                                  <div className="d-flex align-items-center">
                                    <Button className="yellow-outline-button me-2" onClick={() => handleEditarPagina(examen.idExamen)}>
                                      <FaPencilAlt color="#F47735" />
                                    </Button>
                                    <Button className="red-outline-button me-2" onClick={() => eliminarExamen(examen.idExamen)}>
                                      <FaTrash color="red" />
                                    </Button>
                                  </div>
                                ) : null}&nbsp;&nbsp;
                                <Button className="examenbu direction-button" onClick={() => handleHacerTarea(examen.idExamen)}>
                                  Realizar examen <FaPencilRuler className="fa-icon" />
                                </Button>
                              </div>
                            </div>
                          </div>
                          <hr className='lineamodulo' />
                        </Fragment>
                      );
                    }) : examenes.length > 0 ?
                      examenes.map((exameneEs, index) => {
                        // Aquí va el código para renderizar nomtarea en su orden original...
                        return (
                          <Fragment key={index}>
                            <hr className='lineamodulo' />
                            <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                              {/*BOTONES DE REORDENAMIENTO2*/}
                              {/* {usuarioId === 4 || usuarioId === 5 ? (
                                <div className="d-flex align-items-center ahorita-no" style={{ flexDirection: 'column' }}>
                                  
                                  {index > 0 && (
                                    <Button className="boton-arriba blue-outline-button mb-2" onClick={() => confirmMoveExamen(index, index - 1)}>
                                      <FaArrowUp />
                                    </Button>
                                  )}
                                  {index < examenes.length - 1 && (
                                    <Button className="boton-abajo green-outline-button" onClick={() => confirmMoveExamen(index, index + 1)}>
                                      <FaArrowDown />
                                    </Button>
                                  )}
                                </div>
                              ) : null}&nbsp;&nbsp;  */}
                              <span><h4 className="naranjah4 cambio-truncate-moviles me-2">{exameneEs.titulo}</h4></span>&nbsp;&nbsp;
                              <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                                <span><strong>Puntos: {exameneEs.puntos}</strong></span>
                                <div className="d-flex align-items-center justify-content-end">
                                  {usuarioId === 4 || usuarioId === 5 || usuarioId === 7 ? (
                                    <div className="d-flex align-items-center">
                                      <Button className="yellow-outline-button me-2" onClick={() => handleEditarPagina(exameneEs.idExamen)}>
                                        <FaPencilAlt color="#F47735" />
                                      </Button>
                                      <Button className="red-outline-button me-2" onClick={() => eliminarExamen(exameneEs.idExamen)}>
                                        <FaTrash color="red" />
                                      </Button>
                                    </div>
                                  ) : null}&nbsp;&nbsp;
                                  <Button className="examenbu direction-button" onClick={() => handleHacerTarea(exameneEs.idExamen)}>
                                    Realizar examen <FaPencilRuler className="fa-icon" />
                                  </Button>
                                </div>
                              </div>
                            </div>
                            <hr className='lineamodulo' />
                          </Fragment>
                        );
                      })
                      :
                      <h3 className="nohaycursos">No hay Exámenes registrados.</h3>
                    }
                  </div>
                </Collapse>
                <br />
              </div>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}

export default CursoExamen;
